<template>
  <!-- Profile Mobile Tab -->
  <div class="be-popover-top myProfile" v-if="item === 'my_profile'">
    <div class="be-popover-header">
      <span>
        <i class="icon-user-fill f-28" />
      </span>
      <div>
        <p>{{ userNameFemale }}</p>
        <p>{{ userEmail }}</p>
      </div>
    </div>
    <hr />
    <div class="be-popover-center">
      <div
        v-for="(option, idx) in profileOptions"
        :key="idx"
        class="single-item"
        :class="{ active: $route.name === option.route }"
      >
        <router-link
          v-if="option.route && option.route !== '#'"
          :to="{
            name: option.route,
          }"
          @click.native="closeF"
        >
          <i v-if="option.icon" :class="[option.icon, 'f-24']" />
          {{ option.label }}
        </router-link>
        <span v-else-if="option.route === '#'">
          <i v-if="option.icon" :class="[option.icon, 'f-24']" />
          {{ option.label }}
        </span>
      </div>
    </div>
    <hr />
    <div class="be-popover-footer">
      <div class="single-item">
        <a href="#" @click.prevent="profileFooterContent[0].fn">
          <i
            v-if="profileFooterContent[0].icon"
            :class="[profileFooterContent[0].icon, 'f-24']"
          />
          {{ profileFooterContent[0].label }}
        </a>
      </div>
    </div>
  </div>
  <!-- Notification Mobile Tab -->
  <div
    class="be-popover-top myNotifications"
    v-else-if="item === 'my_notifications' && noteOptions.length"
  >
    <div class="be-popover-header">
      <p>Notifications</p>
      <span> {{ noteOptions.length }} </span>
    </div>
    <hr />
    <div class="be-popover-center">
      <div
        class="row m-0"
        v-for="(notification, index) in noteOptions"
        :key="'mob-' + index"
      >
        <div class="d-flex ml-4 mr-4 be-row-content border-bottom">
          <span
            class="icon-info-fill be-text-primary"
            :class="notification.model"
          />

          <div class="col p-0">
            <div class="d-flex justify-content-between align-items-center">
              <p class="text-capitalize m-0 be-row-content-title">
                {{ notification.titre }}
              </p>
              <span class="note-time">
                <!-- <i class="icon feather icon-clock"></i> -->
                {{ notification.created_at | moment("from", "now") }}
              </span>
            </div>
            <p class="m-0 be-row-content-desc">
              {{ notification.label }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="be-popover-footer">
      <router-link :to="{ name: 'notification' }" @click.native="closeF()">
        See All Notifications
      </router-link>
    </div>
  </div>
  <!-- Notification Mobile Tab NoContent -->
  <div
    class="be-popover-top myNotifications"
    v-else-if="item === 'my_notifications' && !noteOptions.length"
  >
    <div class="be-popover-header">
      <p>Notifications</p>
    </div>
    <hr />
    <div class="be-popover-center" style="display: flex;">
      <NoContent :icon="'icon-bell'" :text="'No Data'" />
    </div>
    <hr />
    <div class="be-popover-footer">
      <router-link :to="{ name: 'notification' }" @click.native="closeF()">
        See All Notifications
      </router-link>
    </div>
  </div>
  <!-- Acheter Mobile Tab -->

  <div v-else-if="item === 'my_acheters'" class="be-popover-top myAcheters">
    <div class="be-popover-center">
      <div
        v-for="acheter in remappedServices"
        :key="acheter.id"
        @click="handlerAcheter(acheter)"
        :class="{
          'acheter-active': acheter.index.toLowerCase() === $route.params.index,
        }"
      >
        <img alt="" :src="getImg(acheter.label || 'notFound')" />
        <p>{{ acheter.serviceName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getImg, getServiceName } from "../../helpers/utils";
import NoContent from "../common/NoContent";
import { mapState, mapGetters } from "vuex";

export default {
  name: "BeBottomNavPopover",
  components: { NoContent },
  props: {
    item: {
      type: String,
      required: true,
    },
    closeF: {
      type: Function,
      required: true,
    },
  },
  watch: {
    item(newVal) {
      this.item = newVal;
    },
  },
  data() {
    return {
      getImg,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("shopping", ["activeServices"]),
    remappedServices() {
      return this.activeServices.map(s => ({
        ...s,
        serviceName: getServiceName(s),
      }));
    },
    userNameFemale() {
      return `${this.user.nom} ${this.user.prenom}`;
    },
    userEmail() {
      return `${this.user.email}`;
    },
    profileFooterContent() {
      return this.profileOptions.slice(this.profileOptions.length - 1);
    },
    profileOptions() {
      return [
        {
          label: this.$t("base.navMenu.profile"),
          icon: "icon-user",
          route: "profile",
        },
        {
          label: this.$t("base.navMenu.settings"),
          icon: "icon-settings",
          route: "formSetting",
        },
        {
          label: this.$t("base.navMenu.movements"),
          icon: "icon-movements",
          route: "movement",
        },
        {
          label: this.$t("base.navMenu.documentation"),
          icon: "icon-api",
          route: "#",
        },
        {
          label: this.$t("base.navMenu.logout"),
          icon: "icon-log-out",
          fn: this.logout,
        },
      ];
    },
    // ...mapState('auth', ['metaNotification']),
    // noteOptions() {
    //   return this.metaNotification ? this.metaNotification.data : [];
    // },
    noteOptions() {
      return this.user.notifications.length ? this.user.notifications : [];
    },
    notificationsTotal() {
      return this.user.notifications.length
        ? this.user.notifications.length.toString()
        : "";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        location.assign("/login");
      });
    },
    handlerAcheter(selectedAcheter) {
      this.selectedAcheterId = selectedAcheter.id;
      if (selectedAcheter)
        this.$router
          .push({
            name: "shopping.form",
            params: {
              type_code: selectedAcheter.type_service.code.toLowerCase(),
              index: selectedAcheter.index.toLowerCase(),
            },
          })
          .catch(() => {})
          .finally(() => this.closeF());
    },
  },
};
</script>

<style lang="scss" scoped></style>
