import axios from "axios";

export default {
  getWithdrawOperators() {
    return axios.get("/retraits/operators/list");
  },

  getDepositOperators() {
    return axios.get("/operators/current-user/list");
  },

  getAllOperators(payload) {
    return axios.post("/operators/list", payload);
  },

  addOperatorNumber(number) {
    return axios.post("/operators/numbers", number);
  },

  updateOperatorNumber(number) {
    return axios.put(`/operators/numbers/${number.id}`, number);
  },

  getNumbers(payload) {
    return axios.post("/operators/numbers/list", payload);
  },
};
