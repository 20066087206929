import { render, staticRenderFns } from "./GlobalAlertItem.vue?vue&type=template&id=9722416e&scoped=true&"
import script from "./GlobalAlertItem.vue?vue&type=script&lang=js&"
export * from "./GlobalAlertItem.vue?vue&type=script&lang=js&"
import style0 from "./GlobalAlertItem.vue?vue&type=style&index=0&id=9722416e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9722416e",
  null
  
)

export default component.exports