<template>
  <a href="#" @click.prevent="openTicketDetails" class="">
    <div class="media userlist-box">
      <div class="media-object live-status">
        {{ ticket.messages.length }}
      </div>
      <div class="media-body">
        <div class="chat-header">
          <div class="title">
            <div class="ticket ticket-warning" v-if="ticket.is_active">
              {{ $t("common.loading") }}
            </div>
            <small class="ticket ticket-success" v-else>
              {{ $t("common.finished") }}
            </small>
            <span>
              {{ ticket.type.label }}
            </span>
          </div>
          <small class="text-truncate">
            <span>{{ ticket.reference }}</span>
            <span v-if="amount" class="text-c-green">
              <span class="text-white"> - </span>
              {{ `${amount} ${currency}` }}
            </span>
          </small>
          <small class="f-10">
            - {{ $t("common.waiting") }}
            {{ ticket.created_at | moment("from", "now") }}
          </small>
        </div>
      </div>
      <div class="live-status-placeholder"></div>
    </div>
  </a>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TicketItem",
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["currency"]),
    amount() {
      return this.ticket.amount
        ? this.ticket.amount
        : this.ticket.model
        ? this.ticket.model.base_amount
        : null;
    },
  },

  methods: {
    openTicketDetails() {
      this.loading = true;
      this.$store
        .dispatch("assistance/getTicketByReference", {
          reference: this.ticket.reference.toLowerCase(),
        })
        .then(() => {
          this.loading = false;
          window.$(".header-chat").toggleClass("open");
          document.body.style.overflow = "hidden";
        });
    },
  },
};
</script>

<style scoped></style>
