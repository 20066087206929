<template>
  <div class="chat-empty-list text-center p-2">
    <no-content icon="icon-feedback" :text="message" />
  </div>
</template>

<script>
import { emptyListModel } from "../../helpers/constants";
import NoContent from "../../components/common/NoContent";

export default {
  name: "BaseEmptyList",
  components: {
    NoContent,
  },
  props: {
    model: {
      type: String,
      default: emptyListModel.empty,
    },
    search: {
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentModel() {
      switch (this.model) {
        case emptyListModel.disableWithdraw:
          return emptyListModel.withdraw;
        case emptyListModel.disableDeposit:
          return emptyListModel.deposit;
        default:
          return this.model;
      }
    },
    emptyImage() {
      return `/assets/images/${this.currentModel}.svg`;
    },

    isAssistance() {
      return this.model === emptyListModel.assistance;
    },

    message() {
      switch (this.model) {
        case emptyListModel.movement:
          return this.$t("base.messages.movement");
        case emptyListModel.deposit:
          return this.search
            ? this.$t("base.messages.deposit_with_key") + ` ${this.keyword}`
            : this.$t("base.messages.recharge");
        case emptyListModel.withdraw:
          return this.search
            ? this.$t("base.messages.withdraw_with_key") + ` ${this.keyword}`
            : this.$t("base.messages.withdraw_none");
        case emptyListModel.transfer:
          return this.search
            ? this.$t("base.messages.transfer_with_key") + ` ${this.keyword}`
            : this.$t("base.messages.transfer_none");
        case emptyListModel.shopping:
          return this.search
            ? this.$t("base.messages.shopping_with_key") + ` ${this.keyword}`
            : this.$t("base.messages.shopping_no_order");
        case emptyListModel.payment:
          return this.search
            ? this.$t("base.messages.payment_with_key") + ` ${this.keyword}`
            : this.$t("base.messages.payment_not_received");
        case emptyListModel.assistance:
          return this.$t("base.messages.assistance");
        case emptyListModel.message:
          return this.$t("base.messages.message");
        case emptyListModel.disableWithdraw:
          return this.$t("base.messages.disableWithdraw");
        case emptyListModel.disableDeposit:
          return this.$t("base.messages.disableDeposit");
        default:
          return "";
      }
    },
  },
};
</script>

<style>
.empty-icon {
  max-width: 300px;
}
</style>
