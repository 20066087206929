import axios from "axios";

export default {
  getWithdrawList(page, field) {
    return axios.post("/retraits/list?page=" + page, field);
  },
  searchFromWithdrawList(page, field) {
    return axios.post("/retraits/list/search?page=" + page, field);
  },
  getWithdraw(id) {
    return axios.get(`/retraits/${id}`);
  },
  confirmWithdraw(payload) {
    return axios.post("/retraits", payload);
  },

  getContacts() {
    return axios.post("/contacts/list");
  },
  addContact(contact) {
    return axios.post("/contacts", contact);
  },
  editContact(contact) {
    return axios.put(`/contacts/${contact.id}`, contact);
  },

  addInformation(information) {
    return axios.post("/contacts/informations", information);
  },
  deleteInformation(id) {
    return axios.delete(`/contacts/informations/${id}`);
  },
};
