import { mapGetters } from "vuex";

export const movementMixin = {
  props: {
    movement: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["isCurrentUser"]),

    operationIcon() {
      switch (this.movement.type) {
        case "Crédit":
          return "icon-deposit-fill";
        case "Débit":
          return "icon-withdraw-fill";
      }
    },
    operationClass() {
      switch (this.movement.type) {
        case "Crédit":
          return "text-confirm";
        case "Débit":
          return "text-danger";
      }
    },
    activeClass() {
      switch (this.movement.status) {
        case "Succès":
          return "text-confirm";
        case "Echec":
          return "text-danger";
        case "En cours":
          return "text-info";
      }
    },
    statusClassIcon() {
      switch (this.movement.status) {
        case "Succès":
          return "fas fa-thumbs-up text-success";
        case "Echec":
          return "fas fa-thumbs-down text-danger";
        case "En cours":
          return "fas fa-hourglass-half text-info";
      }
    },
  },
};
