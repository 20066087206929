import store from "@/store";

const withdraw = [
  {
    path: "/",
    redirect: { name: "withdraw" }
  },
  {
    path: "list",
    name: "withdraw",
    component: () => import("../views/withdraw/WithdrawList"),
    children: [
      {
        path: ":id",
        name: "withdraw.detail",
        component: () => import("../views/withdraw/WithdrawDetails"),
        props: true,
        beforeEnter(routeTo, routeFrom, next) {
          store
            .dispatch("withdraw/getWithdraw", routeTo.params.id)
            .then(withdraw => {
              routeTo.params.withdraw = withdraw;
              next();
            })
            .catch(() =>
              next({
                name: "withdraw"
              })
            );
        }
      }
    ]
  },
  {
    path: "initiate",
    name: "withdraw.form",
    component: () => import("../views/withdraw/WithdrawForm"),
    beforeEnter(routeTo, routeFrom, next) {
      Promise.all([
        store.dispatch("withdraw/getWithdrawOperators"),
        store.dispatch("withdraw/getUserContacts")
      ]).then(() => {
        next();
      });
    }
  }
];

export default withdraw;
