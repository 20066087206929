import axios from "axios";

export default {
  getTransferList(page, field) {
    return axios.post("/transferts/list?page=" + page, field);
  },
  searchFromTransferList(page, field) {
    return axios.post("/transferts/list/search?page=" + page, field);
  },
  getTransfer(id) {
    return axios.get(`/transferts/${id}`);
  },
  confirmTransfer(payload) {
    return axios.post("/transferts", payload);
  },
};
