import TransferService from "../../services/TransferService";
import AuthService from "../../services/AuthService";

const state = {
  metaTransfer: null,
};

const getters = {
  transfers: state => (state.metaTransfer ? state.metaTransfer.data : []),
  current_page: state =>
    state.metaTransfer ? state.metaTransfer.current_page : 1,
  last_page: state => (state.metaTransfer ? state.metaTransfer.last_page : 1),
};

const actions = {
  getTransferList({ commit, state }, { page, field, cancel }) {
    if (
      state.metaTransfer &&
      page === state.metaTransfer.current_page &&
      !field.search &&
      !cancel
    )
      return state.metaTransfer;
    else {
      if (field.search)
        return TransferService.searchFromTransferList(page, field).then(
          response => {
            commit("SET_META_TRANSFER", response.data);
          }
        );
      else
        return TransferService.getTransferList(page, field).then(response => {
          commit("SET_META_TRANSFER", response.data);
        });
    }
  },
  getTransfer({ getters, dispatch }, id) {
    const withdraw = getters.transfers.find(w => w.id === id);
    if (withdraw !== undefined) return withdraw;
    else
      return TransferService.getTransfer(id)
        .then(({ data }) => {
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  searchUserByEmail({ dispatch }, email) {
    return AuthService.searchUserByEmail(email)
      .then(({ data }) => {
        const receiver = data.find(u => u.email === email);
        if (receiver !== undefined) return receiver;
        else {
          const message =
            "Nous n'avons trouvé aucun compte BeWallet correspondant à cet email.";
          return Promise.reject({ response: { data: { message } } });
        }
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  confirmTransfer({ commit, dispatch }, { amount, receiver_id }) {
    // if (rootGetters["auth/isCompleteProfile"]) {
    return TransferService.confirmTransfer({
      receiver: receiver_id,
      amount,
    })
      .then(({ data }) => {
        commit("ADD_TRANSFER", data.transfer);
        const notification = {
          type: "success",
          message: data.message,
          is_toast: false,
        };
        dispatch("notification/add", notification, { root: true });
        dispatch("auth/getCurrentUser", null, { root: true });
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          let notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
    // } else
    //   return Promise.reject({
    //     data: {
    //       message:
    //         "Veuillez compléter votre profil pour effectuer un transfert."
    //     }
    //   });
  },
};

const mutations = {
  SET_META_TRANSFER(state, metaTransfer) {
    state.metaTransfer = metaTransfer;
  },
  ADD_TRANSFER(state, transfer) {
    if (state.metaTransfer) state.metaTransfer.data.unshift(transfer);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
