import AssistanceService from "../../services/AssistanceService";
import OperatorService from "../../services/OperatorService";
import { operationStatus, typeTicketCode } from "../../helpers/constants";
import DepositService from "../../services/DepositService";
import WithdrawService from "../../services/WithdrawService";
import PaymentService from "../../services/PaymentService";
import ShoppingService from "../../services/ShoppingService";

const state = {
  metaTicket: null,
  ticket_types: [],
  operators: [],
  metaOperations: [],
  ticket: null,
  numbers: [],
};

// getters
const getters = {
  tickets: state => (state.metaTicket ? state.metaTicket.data : []),
  current_page: state => (state.metaTicket ? state.metaTicket.current_page : 1),
  last_page: state => (state.metaTicket ? state.metaTicket.last_page : 1),
  count_ticket: state => (state.metaTicket ? state.metaTicket.total : 0),
};

// privileges
const actions = {
  getTicketList({ commit }, { page, field }) {
    return AssistanceService.getTickets(page, field).then(({ data }) => {
      if (page === 1) commit("RESET_META_TICKET");

      commit("SET_META_TICKET", data);
      return data;
    });
  },
  getTicketByReference({ commit, state, dispatch }, { reference }) {
    if (state.ticket && state.ticket.reference.toLowerCase() === reference)
      return state.ticket;
    else {
      const ticket = state.metaTicket.data.find(
        t => t.reference.toLowerCase() === reference
      );
      if (ticket !== undefined) {
        commit("SET_TICKET", ticket);
        return ticket;
      } else
        return AssistanceService.getTicketByReference(reference)
          .then(({ data }) => {
            commit("SET_TICKET", data);
            return data;
          })
          .catch(err => {
            if (err.response.data.message) {
              const notification = {
                type: "danger",
                message: err.response.data.message,
                is_toast: true,
              };
              dispatch("notification/add", notification, { root: true });
            }
            commit("SET_TICKET", null);
            return Promise.reject(err.response.data);
          });
    }
  },
  getTicketTypeList({ state, commit }) {
    if (state.ticket_types.length > 0) return state.ticket_types;
    else
      return AssistanceService.getTicketTypes().then(({ data }) => {
        commit("SET_TICKET_TYPES", data);
        return data;
      });
  },

  getAllOperators({ state, commit }, { country_id }) {
    if (state.operators.length > 0) return state.operators;
    else
      return OperatorService.getAllOperators({ country_id }).then(
        ({ data }) => {
          commit("SET_ALL_OPERATORS", data);
          return data;
        }
      );
  },

  getOperations({ state, commit }, { ticketType, operator_id, field, force }) {
    const metaOp = state.metaOperations.find(
      mp =>
        mp.ticket_type_id === ticketType.id &&
        (operator_id
          ? mp.operator_id === operator_id
          : mp.service_id === field.services_id)
    );
    if (metaOp !== undefined && !force) return metaOp.operations;
    else {
      switch (ticketType.code) {
        case typeTicketCode.deposit:
          return DepositService.getDepositList(1, {
            ...field,
            operators_id: operator_id,
          }).then(({ data }) => {
            commit("SET_META_OPERATIONS", {
              ticket_type_id: ticketType.id,
              operator_id,
              service_id: null,
              operations: data.data,
            });
            return data.data;
          });
        case typeTicketCode.withdraw:
          return WithdrawService.getWithdrawList(1, {
            ...field,
          }).then(({ data }) => {
            commit("SET_META_OPERATIONS", {
              ticket_type_id: ticketType.id,
              operator_id,
              service_id: null,
              operations: data.data,
            });
            return data.data.filter(d => d.status !== operationStatus.pending);
          });
        case typeTicketCode.payment:
          return PaymentService.getPaymentList(1, {
            ...field,
            operators_id: operator_id,
          }).then(({ data }) => {
            commit("SET_META_OPERATIONS", {
              ticket_type_id: ticketType.id,
              operator_id,
              service_id: null,
              operations: data.data,
            });
            return data.data;
          });
        default:
          return ShoppingService.getShoppingList(1, {
            ...field,
            type: ticketType.code,
          }).then(({ data }) => {
            commit("SET_META_OPERATIONS", {
              ticket_type_id: ticketType.id,
              operator_id,
              service_id: field.services_id,
              operations: data.data,
            });
            return data.data;
          });
      }
    }
  },

  uploadImage({ dispatch }, formData) {
    return AssistanceService.uploadImage(formData)
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  addNewTicket({ commit, dispatch }, payload) {
    return AssistanceService.addNewTicket(payload)
      .then(({ data }) => {
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          commit("ADD_NEW_TICKET", data);
          const notification = {
            type: "success",
            message: this.state.$t("assistance.complaint_saved_successfully"),
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  addNewNumberTicket({ dispatch }, payload) {
    return AssistanceService.addNewNumberTicket(payload)
      .then(({ data }) => {
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  sendNewMessage({ commit, state, dispatch }, { message }) {
    return AssistanceService.addTicketMessage({
      tickets_id: state.ticket.id,
      message,
    })
      .then(({ data }) => {
        commit("ADD_TICKET_MESSAGE", data);
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  closeTicket({ commit, state, dispatch }) {
    return AssistanceService.updateTicket({ ...state.ticket, is_active: false })
      .then(({ data }) => {
        commit("REMOVE_TICKET", state.ticket);
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  getNumbers({ commit }, payload) {
    return OperatorService.getNumbers(payload).then(({ data }) => {
      commit("SET_NUMBERS", data);
      return data;
    });
  },
};

// mutations
const mutations = {
  RESET_META_TICKET(state) {
    state.metaTicket = null;
  },
  SET_META_TICKET(state, metaTicket) {
    state.metaTicket = {
      ...metaTicket,
      data: [
        ...(state.metaTicket ? state.metaTicket.data : []),
        ...metaTicket.data,
      ],
    };
  },
  SET_TICKET(state, ticket) {
    state.ticket = ticket;
  },
  ADD_NEW_TICKET(state, ticket) {
    if (state.metaTicket) {
      state.metaTicket.data.push(ticket);
      state.metaTicket.total += 1;
    }
  },
  REMOVE_TICKET(state, ticket) {
    ticket.is_active = false;
    state.metaTicket.data = state.metaTicket.data.filter(
      t => t.id !== ticket.id
    );
    state.metaTicket.total -= 1;
  },
  SET_TICKET_TYPES(state, ticket_types) {
    state.ticket_types = ticket_types;
  },
  SET_ALL_OPERATORS(state, operators) {
    state.operators = operators;
  },
  SET_META_OPERATIONS(state, metaOperation) {
    state.metaOperations.push(metaOperation);
  },
  ADD_TICKET_MESSAGE(state, data) {
    state.ticket.messages.push(data);
  },
  SET_NUMBERS(state, data) {
    state.numbers = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
