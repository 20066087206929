<template>
  <h4 class="with-divider p-4 f-w-600" :class="color">
    <span :class="color"> {{ text }} </span>
  </h4>
</template>

<script>
export default {
  name: "HeaderWithDivider",
  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
