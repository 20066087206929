<template>
  <tr class="border-left" :class="'border-' + operationClass">
    <td class="align-middle text-center">
      <i :class="operationIcon + ' text-' + operationClass" />
      <span class="rounded" :class="'text-' + operationClass">
        {{ movement.type }}
      </span>
    </td>
    <td class="align-middle text-left text-truncate" style="width: 70px">
      <label v-html="movement.desc" class="text-secondary f-14 f-w-600" />
      <small class="d-block text-dark">
        <span class="f-w-600"> Ref :</span> {{ movement.reference }}
      </small>
    </td>
    <td class="align-middle text-right">
      {{ movement.amount + " " + movement.currency }}
      <small class="d-block" :class="'text-' + activeClass">
        <i :class="statusClassIcon"></i>
        {{ movement.status }}
      </small>
    </td>
    <td class="align-middle text-right">
      {{ movement.solde + " " + movement.currency }}
    </td>
    <td class="align-middle text-right">
      {{ movement.created_at | moment("DD MMMM YYYY") }}
      <small class="d-block">{{
        movement.created_at | moment("h:mm:ss")
      }}</small>
    </td>
  </tr>
</template>

<script>
import { movementMixin } from "../mixins/movementMixion";

export default {
  name: "MovementTableLine",
  mixins: [movementMixin],
};
</script>

<style scoped></style>
