<template>
  <div class="scroll-content">
    <div
      class="mt-2 be-table"
      v-if="!smallSize"
      :class="[isTablet ? 'isTablet' : '', getFieldsCount]"
    >
      <b-row class="table-head">
        <b-col
          v-for="field in fields"
          :key="field.label"
          class="pl-0 pr-0"
          :class="field.class"
        >
          {{ field.label }}
        </b-col>
      </b-row>
      <div class="table-body">
        <b-row
          class="my-2"
          :class="{ hovered: hoverOnRow, [rowClasses]: true }"
          v-for="(rowData, i) in data"
          :key="i"
        >
          <b-col
            class="px-0"
            v-for="field in fields"
            :key="field.key"
            :class="field.class"
          >
            <slot
              :name="field.key"
              v-if="$scopedSlots[field.key]"
              :row="rowData"
            />
            <span v-else>{{ rowData[field.key] }}</span>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else class="be-table-mobile">
      <div
        v-for="(rowData, rowIdx) in data"
        :key="'row' + rowIdx"
        class="be-table-item-mobile be-card"
        :class="{ [rowClasses]: true }"
      >
        <template v-for="(field, colIdx) in fields">
          <b-row
            v-if="colIdx % 2 === 0 && field.key !== 'actions'"
            :key="field.label"
          >
            <b-col :class="fields[colIdx].class">
              <div class="item-label f-12">
                {{ fields[colIdx].label }}
              </div>
              <div class="f-w-600 f-14">
                <slot
                  :name="fields[colIdx].key"
                  v-if="$scopedSlots[fields[colIdx].key]"
                  :row="rowData"
                />
                <span class="f-w-600" v-else>
                  {{ rowData[fields[colIdx].key] }}
                </span>
              </div>
            </b-col>
            <b-col
              class="pl-0 pr-0"
              :class="fields[colIdx + 1] && fields[colIdx + 1].class"
            >
              <div class="item-label f-12">
                {{ fields[colIdx + 1] && fields[colIdx + 1].label }}
              </div>
              <div v-if="fields[colIdx + 1]" class="f-14 f-w-600">
                <slot
                  :name="fields[colIdx + 1].key"
                  v-if="$scopedSlots[fields[colIdx + 1].key]"
                  :row="rowData"
                />
                <span class="f-14 f-w-600" v-else>
                  {{ rowData[fields[colIdx + 1].key] }}
                </span>
              </div>
            </b-col>
          </b-row>
        </template>
        <div class="actions">
          <slot name="actions" :row="rowData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BeTable",
  computed: {
    ...mapState({
      sidebarState: state => state.sidebarState,
    }),
    getFieldsCount() {
      if (this.fields && this.fields.length) {
        return "f-" + this.fields.length;
      }
      return "";
    },
    isTablet() {
      return !this.sidebarState && this.$screen.width < 1300;
    },
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    hoverOnRow: {
      type: Boolean,
      default: true,
    },
    smallSize: {
      type: Boolean,
      default: false,
    },
    rowClasses: {
      type: String,
      default: "",
    },
    propsForCol: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
.table-head {
  color: #858a93;
  text-transform: uppercase;
  font-weight: 600;
}
.table-body .row {
  border-radius: 5px;
  box-shadow: 0 2px 10px #00000012;
  align-items: center;
  position: relative;
  min-height: 72px;
  border: 1px solid #ffffff00;
}

.hovered:hover {
  border: 1px solid #0071ff;
}

.actions {
  position: absolute;
  right: 15px;
  top: calc(50% - 13.5px);
}
</style>
