export default {
  required: {
    gender: "Veuillez choisir le genre",
    actual_password: "Le mot de passe actuel est requis",
    new_password: "Le nouveau mot de passe est requis",
    confirm_new_password: "Confirmation du nouveau mot de passe est requise",
    password_length_6: "Au moins 6 caractères",
    at_least_one_uppercase: "Au moins une lettre en majuscule",
    at_least_one_number: "Au moins un chiffre en majuscule",
  },
  password_not_matches: "La confirmation du mots de passe ne correspondent pas",
  fees: "FRAIS",
  merchant: "Marchand",
  sms_notifications: "Notifications SMS",
  sms_notifications_desc:
    "Je souhaite recevoir un SMS chaque fois que je paie un service",
  sms_notifications_on_recharge:
    "Je souhaite recevoir un SMS chaque fois que je recharge mon compte Bewallet.",
  sms_notification_on_transfer: "et quand je reçois un transfert d'argent.",
  payment_details: "Détails du paiement",
  payment_amount: "Montant du paiement",
  merchant_payment_list: "Liste des paiements marchants",
  recent_activities: "Activités récentes",
  recent_activity: "Activité récente",
  personal_information: "Informations personnelles",
  account_preferences: "Préférences du compte",
  platform_settings: "Langue de l'application",
  change_password: "Changer le mot de passe",
  actual_password: "Mot de passe actuel",
  new_password: "Nouveau mot de passe",
  confirm_new_password: "Confirmer nouveau mot de passe",
  insert_password_placeholder: "Entrez le mot de passe actuel",
  insert_new_password_placeholder: "Entrez le nouveau mot de passe",
  insert_confirm_password_placeholder: "Confirmation du mot de passe",
  all_operations: "Toutes les opérations",
  operations_history: "Historique des opérations",
  report_generated_at: "Rapport généré le ",
  no_merchant_payment_done: "Vous n’avez effectué aucun paiement marchant !",
  operations_history_in_bewallet:
    "Historique des opérations de votre compte BeWallet",
  no_operations_at_moment:
    "Vous n’avez effectué aucune opération pour le moment.",
  movement_historic_in_bewallet:
    "Historique des mouvements de votre compte BeWallet",
  password_successful_updated:
    "Modification du mot de passe effectuée avec succès.",
};
