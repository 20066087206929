<template>
  <router-link :to="{ name }" class="btn" :class="btnStateClass">
    <i :class="icon" v-if="icon"></i>
    {{ label }}
    <span
      v-if="routerLoading"
      class="spinner-grow spinner-grow-sm m-l-5"
      role="status"
    ></span>
  </router-link>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "BaseRouterLink",
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    btnClass: {
      type: String,
      default: "btn-secondary",
    },
  },
  computed: {
    ...mapState(["routerLoading"]),
    ...mapGetters("auth", ["isCompleteProfile"]),
    isDisabled() {
      return (
        // (!this.isCompleteProfile && this.name !== "deposit.form") ||
        this.routerLoading
      );
    },
    btnStateClass() {
      return this.isDisabled ? `${this.btnClass} disabled` : this.btnClass;
    },
  },
};
</script>

<style scoped></style>
