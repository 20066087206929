export default {
  maintenance: { label: "Maintenance", title: "Opération de Maintenance" },
  profile: { label: "Profil", title: "Configuration" },
  Login: { label: "Authentification", title: "Connexion" },
  Home: { label: "Accueil BeWallet", title: "BeWallet - Accueil" },
  Register: { label: "Enregistrement", title: "Création de compte" },
  EmailVerify: {
    label: "Confirmation",
    title: "Confirmation de l'adresse",
  },
  PasswordReset: {
    label: "Mot de passe oublié",
    title: "Mot de passe oublié",
  },
  PasswordChange: {
    label: "Changement du mot de passe",
    title: "Nouveau mot de passe",
  },
  formSetting: {
    label: "Préference",
    title: "Configuration du compte",
  },
  formPassword: {
    label: "Sécurité",
    title: "Modification du mot de passe",
  },
  movement: {
    label: "Mouvement du compte",
    title: "Historique des opérations",
  },
  merchantPayment: {
    label: "Paiement marchant",
    title: "Liste des paiements marchant",
    detail: { label: "Détails", title: "Description du paiement" },
  },
  notification: {
    label: "Notifications",
    title: "Liste des notifications",
  },
  dashboard: { label: "Tableau de bord", title: "Tableau de bord" },
  assistance: { label: "Demande d'assistance", title: "Centre d'assistance" },
  deposit: {
    label: "Recharges",
    title: "Liste des recharges",
    detail: { label: "Détails", title: "Description du dépôt" },
    form: {
      label: "Formulaire de dépôt",
      title: "Nouvelle recharge",
    },
  },
  withdraw: {
    label: "Retraits",
    title: "Liste des retraits",
    detail: { label: "Détails", title: "Description du retrait" },
    form: {
      label: "Formulaire de retrait",
      title: "Nouveau retrait d'argent",
    },
  },
  transfer: {
    label: "Transferts",
    title: "Liste des transferts",
    detail: { label: "Détails", title: "Description du transfert" },
    form: {
      label: "Formulaire de transfert",
      title: "Nouveau transfert d'argent",
    },
  },
  shopping: {
    list: { label: "Historiques", title: "Liste des achats de services" },
    detail: { label: "Détails", title: "Description de l'achat" },
    form: {
      label: "Acheter un service",
      title: "Achat de services",
      travel: { label: "Voyages disponibles", title: "Recherche de voyages" },
      process: { label: "Confirmation", title: "Confirmer votre voyage" },
    },
    bills: {
      label: "Liste des factures disponibles",
      title: "Liste des factures",
    },
  },
  payment: {
    label: "Applications",
    title: "Liste des applications",
    list: { label: "Paiements", title: "Liste des paiements" },
    detail: { label: "Détails", title: "Description du paiement" },
    addApp: {
      label: "Nouvelle Application",
      title: "Ajouter une nouvelle application",
    },
    editApp: {
      label: "Modification",
      title: "Modifier votre application",
    },
  },
};
