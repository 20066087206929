<template>
  <div class="row">
    <div class="d-flex align-items-center">
      <div class="col-2 pl-0">
        <content-placeholders :centered="true" :rounded="true">
          <content-placeholders-img style="width:30px; height: 30px" />
        </content-placeholders>
      </div>
      <div class="col-7 pl-0 pr-0">
        <content-placeholders :centered="true" :rounded="true">
          <content-placeholders-text :lines="2" />
        </content-placeholders>
      </div>
      <div class="col-auto text-center">
        <content-placeholders :centered="true" :rounded="true">
          <content-placeholders-text :lines="2" />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTableRowLoader",
};
</script>

<style scoped></style>
