import store from "@/store";

export function accessTypeControl(routeTo, routeFrom, next) {
  return store
    .dispatch("shopping/getServiceTypeByCode", routeTo.params.type_code)
    .then(response => {
      routeTo.params.serviceType = response.type;
      routeTo.params.services = response.services;
      if (response.services.length === 1 && !routeTo.params.index)
        next({
          name: "shopping.form",
          params: {
            type_code: routeTo.params.type_code,
            index: response.services[0].index.toLowerCase(),
          },
        });
    })
    .catch(() =>
      next({
        name: "dashboard",
      })
    );
}

export function accessServiceControl(routeTo, routeFrom, next) {
  return store
    .dispatch("shopping/getServiceByIndex", routeTo.params.index)
    .then(service => {
      routeTo.params.service = service;
    })
    .catch(() =>
      next({
        name: "shopping.form",
        params: { type_code: routeTo.params.type_code },
      })
    );
}

export function accessTravelsControl(routeTo, routeFrom, next) {
  if (store.state.shopping.agencies.length > 0) {
    routeTo.params.searchField = routeTo.query;
    return true;
  } else
    return store
      .dispatch("shopping/searchTravels", {
        code: routeTo.params.index,
        field: routeTo.query,
      })
      .then(() => {
        routeTo.params.searchField = routeTo.query;
      })
      .catch(() => {
        return next({
          name: "shopping.form",
          params: routeTo.params,
        });
      });
}
