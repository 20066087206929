import Vue from "vue";
import store from "./store";

import vuexI18n from "vuex-i18n";
import fr_FR from "./langs/fr";
import en_EN from "./langs/en";

const config = {
  moduleName: "i18n",
  translateFilterName: "t",
  onTranslationNotFound(locale, key) {
    console.warn(`i18n :: Key '${key}' not found for locale '${locale}'`);
  },
};

Vue.use(vuexI18n.plugin, store, config);
Vue.i18n.add("fr", fr_FR);
Vue.i18n.add("en", en_EN);
Vue.i18n.set("fr");

Vue.prototype.$i18n = Vue.i18n;

export default Vue;
