<template>
  <td class="align-middle text-center f-w-600 pt-2" colspan="2">
    <a href="#" @click.prevent="$emit('click')" class="text-dark">
      <i class="fas fa-life-ring" />
      {{ $t("base.create_new_ticket_on_operator") }}
    </a>
  </td>
</template>

<script>
export default {
  name: "BaseCreateTicketLine",
};
</script>

<style scoped></style>
