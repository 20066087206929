<template>
  <div class="d-flex align-items-center justify-content-center flex-fill">
    <div class="loader01"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
