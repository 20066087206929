<template>
  <footer class="text-center">
    <small class="footer-text">
      © 2018 - {{ date.getFullYear() }} BeWallet {{ $t("copyright.reserved") }}
      {{ $t("copyright.build_by") }}
      <a href="https://lab2view.com" target="_blank">
        Lab2View
      </a>
    </small>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      date: new Date(),
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  height: 40px;
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  .footer-text {
    opacity: 0.7;
  }
}
</style>
