import Vue from 'vue';

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

// const base_url = window.location.origin;
// eslint-disable-next-line no-undef
(async () => {
  // await Vue.loadScript(`${base_url}/assets/js/vendor-all.min.js`);
  // await Vue.loadScript(
  //   `${base_url}/assets/plugins/bootstrap/js/bootstrap.min.js`
  // );
  // await Vue.loadScript(`${base_url}/assets/plugins/prism/js/prism.min.js`);
})();
