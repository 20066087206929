import store from "@/store";

const deposit = [
  {
    path: "/",
    redirect: { name: "deposit" },
  },
  {
    path: "list",
    name: "deposit",
    component: () => import("../views/deposit/DepositList"),
    children: [
      {
        path: ":id",
        name: "deposit.detail",
        component: () => import("../views/deposit/DepositDetails"),
        props: true,
        beforeEnter(routeTo, routeFrom, next) {
          store
            .dispatch("deposit/getDeposit", routeTo.params.id)
            .then(deposit => {
              routeTo.params.deposit = deposit;
              next();
            })
            .catch(() =>
              next({
                name: "deposit",
              })
            );
        },
      },
    ],
  },
  {
    path: "initiate",
    name: "deposit.form",

    component: () => import("../views/deposit/DepositForm"),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("deposit/getDepositOperators").then(() => {
        next();
      });
    },
  },
];

export default deposit;
