<template>
  <div id="base-input-wrapper">
    <div
      class="input-label f-w-600"
      v-if="!!label"
      :class="{ 'hljs-strong': bold, 'input-danger': errorMessage }"
    >
      {{ label }}
    </div>
    <div
      class="input-group position-relative"
      :class="{ leftIconInactive: !!leftIconClass && !value }"
    >
      <i v-if="leftIconClass" v-bind:class="[leftIconClass, 'input-icon']"></i>
      <span v-if="isPhoneInput" class="country-code">+{{ countryCode }}</span>
      <input
        v-on="listeners"
        v-bind="$attrs"
        :value="value"
        :disabled="disabled"
        :class="{
          'pl-65': isPhoneInput,
          'is-invalid': isInvalid,
          wInput: true,
          disabled: disabled,
          'hljs-strong': !!bold,

          hasLeftIcon: !!leftIconClass,
        }"
        @input="updateValue"
      />
      <span v-if="rightText" class="input-icon-text">{{ rightText }}</span>
      <i v-if="rightIconClass" v-bind:class="[rightIconClass, 'rightIcon']"></i>
      <slot />
    </div>
    <div v-if="description && !errorMessage" class="input-description">
      {{ description }}
    </div>
    <div v-if="errorMessage" class="input-error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { formFieldMixin } from "../../mixins/formFieldMixin";

export default {
  name: "BaseInput",
  mixins: [formFieldMixin],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
  },
};
</script>

<style scoped>
.country-code {
  position: absolute;
  top: 15px;
  z-index: 1;
  font-size: 14px;
  color: #545454;
  left: 30px;
}
.pl-65 {
  padding-left: 65px;
}
</style>
