import store from "@/store";
import {
  accessServiceControl,
  accessTravelsControl,
  accessTypeControl
} from "../middleware/shoppingMiddleware";

const shopping = [
  {
    path: "/",
    redirect: { name: "shopping" }
  },
  {
    path: "list",
    name: "shopping.list",
    component: () => import("../views/shopping/ShoppingList"),
    children: [
      {
        path: ":id",
        name: "shopping.detail",
        component: () => import("../views/shopping/ShoppingDetails"),
        props: true,
        beforeEnter(routeTo, routeFrom, next) {
          store
            .dispatch("shopping/getShopping", routeTo.params.id)
            .then(shopping => {
              routeTo.params.shopping = shopping;
              next();
            })
            .catch(() =>
              next({
                name: "shopping.list"
              })
            );
        }
      }
    ]
  },
  {
    path: ":type_code/initiate/:index?",
    name: "shopping.form",
    component: () => import("../views/shopping/ShoppingForm"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      Promise.all([
        accessTypeControl(routeTo, routeFrom, next),
        accessServiceControl(routeTo, routeFrom, next)
      ]).then(() => {
        next();
      });
    }
  },
  {
    path: ":type_code/initiate/:index/travels",
    name: "shopping.form.travel",
    component: () => import("../views/shopping/ShoppingFormTravels"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      Promise.all([
        accessTypeControl(routeTo, routeFrom, next),
        accessServiceControl(routeTo, routeFrom, next),
        accessTravelsControl(routeTo, routeFrom, next)
      ]).then(() => {
        next();
      });
    }
  },
  {
    path: ":type_code/initiate/:index/travels/:token_trip",
    name: "shopping.form.process",
    component: () => import("../views/shopping/ShoppingProcessTravel"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      Promise.all([
        accessServiceControl(routeTo, routeFrom, next),
        store
          .dispatch("shopping/getTravelTripByToken", routeTo.params.token_trip)
          .then(travel => {
            routeTo.params.travel = travel;
            next();
          })
          .catch(() => {
            return next({
              name: "shopping.form",
              params: routeTo.params
            });
          })
      ]).then(() => {
        next();
      });
    }
  },
  {
    path: ":type_code/initiate/:index/:reference/bills",
    name: "shopping.bills",
    component: () => import("../views/shopping/ShoppingBills"),
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch("shopping/getServiceByIndex", routeTo.params.index)
        .then(service => {
          routeTo.params.service = service;
          const userService = service.user_services.find(
            us => us.reference === routeTo.params.reference
          );
          if (userService !== undefined) {
            routeTo.params.userService = userService;
            next();
          } else {
            store.dispatch("notification/add", {
              type: "danger",
              message:
                "Une erreur est survenu lors de la récupération de vos factures. Veuillez contacter le service client s'il vous plaît.",
              is_toast: false
            });
            next({
              name: "shopping.form",
              params: { type_code: routeTo.params.type_code }
            });
          }
        })
        .catch(() =>
          next({
            name: "shopping.form",
            params: { type_code: routeTo.params.type_code }
          })
        );
    }
  }
];

export default shopping;
