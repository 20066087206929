import axios from "axios";

export default {
  getApplicationList(page, field) {
    return axios.post("/applications/list?page=" + page, field);
  },
  addApplication(payload) {
    return axios.post("/applications/create/store", payload);
  },
  updateApplication(application) {
    return axios.put(`/applications/${application.id}`, application);
  },
  uploadApplicationLogo(appId, payload) {
    return axios.post(`/applications/${appId}/logo`, payload);
  },
  getPaymentList(page, field) {
    return axios.post("/payments/list?page=" + page, field);
  },
  searchFromPaymentList(page, field) {
    return axios.post("/payments/list/search?page=" + page, field);
  },
  getPayment(id) {
    return axios.get(`/payments/${id}`);
  },
};
