import store from '@/store';

const transfer = [
  {
    path: '/',
    redirect: { name: 'transfer' },
  },
  {
    path: 'list',
    name: 'transfer',
    component: () => import('../views/transfer/TransferList'),
    children: [
      {
        path: ':id',
        name: 'transfer.detail',
        component: () => import('../views/transfer/TransferDetails'),
        props: true,
        beforeEnter(routeTo, routeFrom, next) {
          store
            .dispatch('transfer/getTransfer', routeTo.params.id)
            .then(transfer => {
              routeTo.params.transfer = transfer;
              next();
            })
            .catch(() =>
              next({
                name: 'transfer',
              })
            );
        },
      },
    ],
  },
  {
    path: 'initiate',
    name: 'transfer.form',
    component: () => import('../views/transfer/TransferForm'),
  },
];

export default transfer;
