<template>
  <!-- <div class="alert alert-danger mb-0" v-if="alerts.length > 0"> -->
  <!-- <div
      id="carouselExampleControls"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div
          class="carousel-item"
          v-for="(alert, index) in alerts"
          :key="index"
          :class="{ active: index === 0 }"
        >
          <GlobalAlertItem
            :message="alert.message"
            :router-name="alert.routerName"
            :router-title="alert.routerTitle"
          />
        </div>
      </div>
    </div>
  </div> -->
  <div class="d-flex align-items-center be-alert" v-if="alerts.length > 0">
    <span class="icon-info-fill be-text-primary" />
    <div class="be-alerts-container">
      <transition name="fade">
        <GlobalAlertItem
          :message="activeItem.message"
          :router-name="activeItem.routerName"
          :router-title="activeItem.routerTitle"
          v-if="activeItem"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { phoneLengthByCallingCode } from "../../helpers/utils";
import GlobalAlertItem from "../profile/GlobalAlertItem";

export default {
  name: "AccountAlert",
  components: { GlobalAlertItem },
  data() {
    return {
      activeItem: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", [
      "isCompleteProfile",
      "isCivCountry",
      "isBenCountry",
      "isSenCountry",
    ]),
    haveInvalidPhoneNumber() {
      return this.user.phone
        ? this.user.phone.toString().length !==
            phoneLengthByCallingCode(this.user.country.calling_code)
        : true;
    },
    phoneNotConfirm() {
      return !this.user.phone_verified_at;
    },
    alerts() {
      let alerts = [];
      // alerts.push({
      //   message:
      //     "En vu d'améliorer la qualité de la plateforme BeWallet, des travaux de maintenance qui rendront indisponible l'ensemble de nos services sont prévus ce Dimanche 27/03/2022 à partir de 23H." +
      //     " Les travaux se poursuivront jusqu'au lundi 28/03/2022 à 12h. " +
      //     "Toutes nos excuses pour le désagrément.",
      //   routerTitle: null,
      //   routerName: null,
      // });
      if (this.isBenCountry)
        alerts.push({
          message: this.$t("layout.is_ben_country_desc"),
          routerTitle: null,
          routerName: null,
        });
      // if (this.isSenCountry)
      //   alerts.push({
      //     message: this.$t("layout.is_sen_country_desc"),
      //     routerTitle: null,
      //     routerName: null
      //   });
      if (!this.isCompleteProfile)
        alerts.push({
          message: this.$t("layout.is_complete_profile_desc"),
          routerTitle: this.$t("layout.complete_now"),
          routerName: "profile",
        });
      if (this.haveInvalidPhoneNumber)
        alerts.push({
          message: this.$t("layout.have_invalid_phone_number_desc"),
          routerTitle: this.$t("layout.update_now"),
          routerName: "profile",
        });
      // if (this.phoneNotConfirm)
      //   alerts.push({
      //     message: this.$t("layout.phone_not_confirm_desc"),
      //     routerTitle: this.$t("layout.confirm_now"),
      //     routerName: "profile"
      //   });

      return alerts;
    },
  },
  created() {
    const vm = this;
    this.activeItem = this.alerts[0];
    let x = 0;
    if (this.alerts.length > 1) {
      setInterval(() => {
        x = x + 1;
        if (x >= this.alerts.length) {
          x = 0;
        }
        vm.activeItem = null;
        this.$nextTick(() => {
          vm.activeItem = vm.alerts[x];
        });
      }, 5000);
    }
  },
};
</script>

<style lang="scss" scoped>
.be-alert {
  border: 1px dashed #0071ff;
  border-radius: 68px;
  margin: 25px 30px 0;
  padding: 25px;
  @media (max-width: 786px) {
    flex-direction: column !important;
    border-radius: 6px;
    margin: 10px;
    padding: 25px 15px;
  }

  > .icon-info-fill {
    font-size: 68px;
    margin-right: 20px;
    @media (max-width: 786px) {
      margin: 0;
    }
  }

  .be-alerts-container {
    flex: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
