<template>
  <nav
    class="sidebar-menu"
    :class="{
      'be-opened-sidebar': isSidebarOpened,
      'be-closed-sidebar': !isSidebarOpened,
    }"
  >
    <span class="menu-controller" @click="sidebarStateChange">
      <i
        :class="{
          'icon-menu-open': isSidebarOpened,
          'icon-menu-close': !isSidebarOpened,
        }"
      />
    </span>
    <div class="menu-items">
      <ul>
        <li>
          <a
            class="menu-item-link"
            :class="getRouteActiveClass('dashboard')"
            @click="routerPush('dashboard')"
          >
            <span><i class="icon-dashboard"></i></span>
            <span class="item-name">{{ $t("utils.dashboard") }}</span>
          </a>
          <div class="be-popover-right" v-if="!isSidebarOpened">
            <div class="single-item">
              <a
                :class="getRouteActiveClass('dashboard')"
                @click="routerPush('dashboard')"
              >
                {{ $t("utils.dashboard") }}
              </a>
            </div>
          </div>
        </li>
        <li>
          <a
            class="menu-item-link"
            :class="getRouteActiveClass('deposit')"
            @click="routerPush('deposit')"
          >
            <span><i class="icon-deposit"></i></span>
            <span class="item-name">{{ $t("utils.recharges") }}</span>
          </a>
          <div class="be-popover-right" v-if="!isSidebarOpened">
            <div class="single-item">
              <a
                :class="getRouteActiveClass('deposit')"
                @click="routerPush('deposit')"
              >
                {{ $t("utils.recharges") }}
              </a>
            </div>
          </div>
        </li>
        <li>
          <a
            class="menu-item-link"
            :class="getRouteActiveClass('withdraw')"
            @click="routerPush('withdraw')"
            ><span> <i class="icon-withdraw"></i></span>
            <span class="item-name">{{ $t("utils.withdrawals") }}</span>
          </a>
          <div class="be-popover-right" v-if="!isSidebarOpened">
            <div class="single-item">
              <a
                :class="getRouteActiveClass('withdraw')"
                @click="routerPush('withdraw')"
                >{{ $t("utils.withdrawals") }}
              </a>
            </div>
          </div>
        </li>
        <li>
          <a
            class="menu-item-link"
            :class="getRouteActiveClass('transfer')"
            @click="routerPush('transfer')"
          >
            <span><i class="icon-transfer"></i></span>
            <span class="item-name">{{ $t("utils.transfers") }}</span>
          </a>
          <div class="be-popover-right" v-if="!isSidebarOpened">
            <div class="single-item">
              <a
                @click="routerPush('transfer')"
                :class="getRouteActiveClass('transfer')"
              >
                {{ $t("utils.transfers") }}
              </a>
            </div>
          </div>
        </li>
        <li class="mt-20">
          <a
            href="#"
            class="menu-item-link"
            :aria-expanded="isSubmenuOpened ? 'true' : 'false'"
            aria-controls="collapse-payers-service"
            @click="submenuStateChange"
            :class="getRouteActiveClass('shopping.form', 'active')"
          >
            <span><i class="icon-card"></i></span>
            <span
              :class="{
                'item-name': true,
                'with-arrow': true,
                active: getRouteActiveClass('shopping.form', 'active'),
                opened: isSubmenuOpened,
              }"
            >
              {{ $t("utils.buy_a_service") }}
            </span>
          </a>
          <div class="be-popover-right items-list" v-if="!isSidebarOpened">
            <div v-if="service_types">
              <div
                v-for="service_type in service_types"
                :key="service_type.id"
                class="single-item"
              >
                <a
                  @click="
                    routerPush('shopping.form', {
                      params: {
                        type_code: service_type.code.toLowerCase(),
                      },
                    })
                  "
                  :class="
                    getShoppingRoutePatchMatch(service_type.code.toLowerCase())
                  "
                  >{{ service_type.web_menu }}
                </a>
              </div>
            </div>
          </div>
          <b-collapse id="collapse-payers-service" v-model="isSubmenuOpened">
            <ul class="menu-child">
              <template v-if="service_types">
                <li
                  v-for="service_type in service_types"
                  :key="service_type.id"
                >
                  <a
                    @click="
                      routerPush('shopping.form', {
                        params: {
                          type_code: service_type.code.toLowerCase(),
                        },
                      })
                    "
                    :class="
                      getShoppingRoutePatchMatch(
                        service_type.code.toLowerCase()
                      )
                    "
                    >{{ service_type.web_menu }}
                  </a>
                </li>
              </template>
            </ul>
          </b-collapse>
        </li>

        <li class="mb-20">
          <a
            @click="routerPush('shopping.list')"
            class="menu-item-link"
            :class="getRouteActiveClass('shopping.list')"
          >
            <span><i class="icon-history"></i></span>
            <span class="item-name">{{ $t("utils.history") }}</span>
          </a>
          <div class="be-popover-right" v-if="!isSidebarOpened">
            <div class="single-item">
              <a
                @click="routerPush('shopping.list')"
                :class="getRouteActiveClass('shopping.list')"
              >
                {{ $t("utils.history") }}
              </a>
            </div>
          </div>
        </li>
        <li>
          <a
            @click="routerPush('assistance')"
            class="menu-item-link orange"
            :class="'active'"
          >
            <span><i class="icon-feedback-fill"></i></span>
            <span class="item-name">{{ $t("utils.do_reclamation") }}</span>
          </a>
          <div class="be-popover-right" v-if="!isSidebarOpened">
            <div class="single-item">
              <a
                @click="routerPush('assistance')"
                class="orange"
                :class="getRouteActiveClass('assistance')"
              >
                {{ $t("utils.do_reclamation") }}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { assistanceMixin } from "../../mixins/assistanceMixiin";

export default {
  name: "Slider",
  mixins: [assistanceMixin],
  props: {
    isSidebarOpened: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSubmenuOpened: false,
    };
  },
  computed: {
    ...mapGetters("shopping", ["service_types"]),
    ...mapGetters("auth", ["isCurrentUserMerchant"]),
    loading() {
      return this.service_types ? this.service_types.length === 0 : true;
    },
    logo() {
      return this.isSidebarOpened
        ? "../../assets/images/logo/logo_sidebar_opened.svg"
        : "../../assets/images/logo/logo48x36.png";
    },
  },

  methods: {
    routerPush(route, params) {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.$router.push({ name: [route], ...params }).catch(() => {});
      if (width < 992) {
        this.$emit("toggle-sidebar");
      }
    },
    getRouteActiveClass(name, activeClass = "active") {
      return RegExp(name + "*").test(this.$route.name) ? activeClass : "";
    },
    getShoppingRoutePatchMatch(code) {
      return RegExp("shopping.form*").test(this.$route.name) &&
        this.$route.params.type_code === code
        ? "active"
        : "";
    },
    sidebarStateChange() {
      this.$emit("toggle-sidebar");
      if (this.isSubmenuOpened) {
        this.isSubmenuOpened = false;
      }
    },
    submenuStateChange() {
      if (this.isSidebarOpened) {
        this.isSubmenuOpened = !this.isSubmenuOpened;
      }
    },
  },
};
</script>

<style scoped></style>
