import CallbackService from "../services/CallbackService";

export function blockchainMonitorCallback(routeTo, routeFrom, next) {
  return CallbackService.blockchainMonitorCallback(
    routeTo.query,
    routeTo.params
  ).then(({ data }) => {
    console.log(data);
    next();
  });
}
