import axios from "axios";

export default {
  getLandingServices() {
    return axios.get("/services/list/landing");
  },
  getServiceList(page, field) {
    return axios.post("/services/list?page=" + page, field);
  },
  getShoppingList(page, field) {
    return axios.post("/shoppings/list?page=" + page, field);
  },
  searchFromShoppingList(page, field) {
    return axios.post(
      `/shoppings/list/search/${field.type}?page=${page}`,
      field
    );
  },
  getShopping(id) {
    return axios.get(`/shoppings/${id}`);
  },
  getServiceByIndex(index) {
    return axios.get(`/services/${index}/get`);
  },
  getServiceTypeByCode(code) {
    return axios.get(`/services/types/${code}`);
  },
  getTravelCities() {
    return axios.get("/shoppings/voyage/villes");
  },
  searchTravels(code, field) {
    return axios.post(`shoppings/voyage/${code}`, field);
  },
  getPassengers() {
    return axios.get("/services/travels/passengers");
  },
  addPassenger(passenger) {
    return axios.post("/services/travels/passengers", passenger);
  },
  processTravelPayment(payload) {
    return axios.post("/shoppings/voyage/route/pay-ticket", payload);
  },
  addUserService(payload) {
    return axios.post("/services/add", payload);
  },
  updateUserService(userService) {
    return axios.put(`/services/edit/${userService.id}`, userService);
  },
  getCanalPlusProductList() {
    return axios.get("/shoppings/canal/list");
  },
  quoteShoppingRequest(code, payload) {
    return axios.post(`/shoppings/quotes/${code}`, payload);
  },
  confirmShoppingRequest(code, payload) {
    return axios.post(`/shoppings/payments/${code}`, payload);
  },
  searchBills(code, reference) {
    return axios.get(`/shoppings/search-bills/${code}/${reference}`);
  },
};
