<template>
  <ul class="pagination align-items-center justify-content-end pr-4 my-0">
    <li
      class="page-item arrow"
      :class="{ disabled: current_page - 1 <= 0 }"
      @click.prevent="!firstDisabled && onPageOpen(current_page - 1)"
    >
      <a class="" :class="{ small: isSmall }" href="#">
        <span>
          <i class="icon-arrow-left-thick" />
        </span>
      </a>
    </li>
    <li
      class="page-item"
      v-if="current_page > 1"
      @click.prevent="onPageOpen(1)"
    >
      <a class="page-link2" :class="{ small: isSmall }" href="#">1</a>
    </li>
    <li v-if="current_page > 4" class="page-item dots">...</li>
    <li
      class="page-item"
      v-if="current_page - 2 > 1 && !(current_page >= 5)"
      @click.prevent="onPageOpen(current_page - 2)"
    >
      <a class="page-link2" :class="{ small: isSmall }" href="#">{{
        current_page - 2
      }}</a>
    </li>
    <li
      class="page-item"
      v-if="current_page - 1 >= 2"
      @click.prevent="onPageOpen(current_page - 1)"
    >
      <a class="page-link2" :class="{ small: isSmall }" href="#">{{
        current_page - 1
      }}</a>
    </li>
    <li class="page-item active" :class="'disabled'">
      <a class="page-link2" :class="{ small: isSmall }" href="#">{{
        current_page
      }}</a>
    </li>
    <li
      class="page-item"
      v-if="current_page + 1 < last_page"
      @click.prevent="onPageOpen(current_page + 1)"
    >
      <a class="page-link2" :class="{ small: isSmall }" href="#">{{
        current_page + 1
      }}</a>
    </li>
    <li
      class="page-item"
      v-if="current_page + 2 < last_page && !(last_page - current_page > 2)"
      @click.prevent="onPageOpen(current_page + 2)"
    >
      <a class="page-link2" :class="{ small: isSmall }" href="#">{{
        current_page + 2
      }}</a>
    </li>
    <li v-if="last_page - current_page > 3" class="page-item dots">...</li>
    <li
      class="page-item"
      v-if="current_page !== last_page"
      @click.prevent="onPageOpen(last_page)"
    >
      <a class="page-link2" :class="{ small: isSmall }" href="#">{{
        last_page
      }}</a>
    </li>
    <li
      class="page-item arrow"
      :class="{ disabled: current_page + 1 > last_page }"
      @click.prevent="!lastDisabled && onPageOpen(current_page + 1)"
    >
      <a class="" :class="{ small: isSmall }" href="#">
        <span>
          <i class="icon-arrow-right-thick" />
        </span>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: "BasePagination",
  props: {
    last_page: {
      type: Number,
      required: true,
    },
    current_page: {
      type: Number,
      required: true,
    },
    isSmall: { type: Boolean, default: false },
  },

  methods: {
    onPageOpen(page) {
      this.$emit("page", page);
    },
  },
  computed: {
    firstDisabled() {
      return this.current_page - 1 <= 0;
    },
    lastDisabled() {
      return this.current_page + 1 > this.last_page;
    },
  },
};
</script>

<style></style>
