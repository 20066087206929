import store from "@/store";

const payment = [
  {
    path: "/",
    redirect: { name: "payment" }
  },
  {
    path: "applications",
    name: "payment",
    component: () => import("../views/payment/PaymentAppList")
  },
  {
    path: "applications/create",
    name: "payment.addApp",
    component: () => import("../views/payment/PaymentAppForm")
  },
  {
    path: "applications/edit/:appId",
    name: "payment.editApp",
    component: () => import("../views/payment/PaymentAppForm")
  },
  {
    path: ":appId/list",
    props: true,
    name: "payment.list",
    component: () => import("../views/payment/PaymentList"),
    children: [
      {
        path: ":id",
        name: "payment.detail",
        component: () => import("../views/payment/PaymentDetails"),
        props: true,
        beforeEnter(routeTo, routeFrom, next) {
          store
            .dispatch("payment/getPayment", routeTo.params.id)
            .then(payment => {
              routeTo.params.payment = payment;
              next();
            })
            .catch(() =>
              next({
                name: "payment"
              })
            );
        }
      }
    ]
  }
];

export default payment;
