const state = {
  notifications: [],
  notificationSystem: {
    options: {
      success: {
        position: "bottomRight",
      },
      error: {
        position: "topRight",
      },
    },
  },
};

const getters = {};

const actions = {
  add({ commit }, notification) {
    commit("PUSH", notification);
  },

  remove({ commit }, notification) {
    commit("DELETE", notification);
  },
};

let nextId = 0;
const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++,
    });
  },

  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      notification => notification.id !== notificationToRemove.id
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
