export default {
  confirm_payment: "Confirmer le paiement",
  no_passengers: "Pas de passagers",
  passengers_number: "Nombre De Passagers",
  available_tickets: "Billets diponibles",
  number_of_tickets_still_available: "Nombre de billets encore diponible",
  travel_infos: "Informations sur le voyage",
  departure_time: "Heure de départ",
  arriving_time: "Heure d'arrivée",
  ticket_expiration: "Expiration du billet",
  no_purchased_bewallet_service:
    "Vous n'avez acheté aucun service BeWallet pour le moment.",
  deposit_identifier: "Identifiant du dépôt",
  purchase_service_list: "Liste des achats de services",
  filter_by_service: "Filtrer par service",
  travel_result_of_departure: "Résultat de la recherche des voyages de",
  buy_all: "Tout payer",
  the: "le",
  to: "au",
  travel_process_notes:
    "NB: BeWallet ne vous permet pas encore de modifier ou d'annuler votre ticket. Toute modification se fera en agence.",
  travel_of: "Voyage du",
  travel_from_to: "De {from} pour {to}",
  invoices_list: "Liste des factures",
  search_and_pay_bill: "Rechercher et payer automatiquement mes factures.",
  insert_the_value: "Entrez la valeur",
  insert_9_digits:
    "Entrez les 09 premiers chiffres de votre identifiant se terminant par 000",
  card_length_6: "Le champs doit avoir 6 premiers chiffres",
  card_length_4: "Le champs doit avoir 4 derniers chiffres",
  customer_id_length:
    "le numéro d'identification client doit avoir 10 chiffres",
  required: {
    customer_name: "Veuillez saisir le nom du client",
  },
  ask_select: "Veuillez saisir",
  last_4_digits: "4 derniers chiffres",
  last_4_digits_desc:
    "Veuillez saisir les 4 derniers chiffres du numéro de la carte",
  choose_bank_required: "Veuillez choisir la banque",
  insert_identification_number: "Numéro d'identification client",
  insert_identification_number_placeholder: "Entrez le numéro d'identification",
  insert_identification_number_desc:
    "Veuillez saisir le numéro d'identification client",
  select_the_bank: "Sélectionner la banque",
  select_the_bank_card: "Choisir la banque de la carte",
  card_number: "Numéro de la carte",
  first_6_digits: "6 premiers chiffres",
  first_6_digits_desc:
    "Veuillez saisir les 6 premiers chiffres du numéro de la carte",
  the_required_value_length:
    "La valeur du {label} doit etre {length} caractères",
  service_not_available: "SERVICE INDISPONIBLE",
  buy: "Acheter",
  a_ticket: "Un billet",
};
