<template>
  <div>
    <div class="btn-group border rounded btn-block">
      <button
        type="button"
        class="btn btn-light dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        :disabled="disabled"
      >
        {{
          selectElement
            ? selectElementName
            : placeholder
            ? placeholder
            : $t("base.choose_operator")
        }}
      </button>
      <div
        class="dropdown-menu dropdown-menu-right"
        x-placement="bottom-end"
        style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(207px, 54px, 0px);"
      >
        <a
          class="dropdown-item"
          :class="{ 'text-info f-w-600': element && element.id === value }"
          href="#"
          @click.prevent="$emit('input', service ? element.index : element.id)"
          v-for="element in elements"
          :key="element.id"
        >
          <img
            class="w-25 rounded"
            style="cursor: pointer;"
            :src="service ? element.icon : element.logo_mobile"
            :alt="element.label"
          />
          {{ element.label }}
        </a>
      </div>
    </div>
    <small
      class="form-text text-right text-danger f-w-300"
      v-if="elements.length === 1 && !service"
      ><em>
        {{ $t("base.just_operator") }}
        <b class="f-w-600">{{ elements[0].label }}</b>
        {{ $t("base.is_available_at_moment") }}.
      </em>
    </small>
  </div>
</template>

<script>
import { getServiceName } from "../../helpers/utils";

export default {
  name: "BaseChooseElement",
  props: {
    elements: {
      type: Array,
      required: true,
    },
    serviceType: {
      type: Object,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
    withdraw: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },

  watch: {
    elements(value) {
      if (value.length === 1)
        this.$emit("input", this.service ? value[0].index : value[0].id);
    },
  },

  created() {
    if (this.elements.length === 1)
      this.$emit(
        "input",
        this.service ? this.elements[0].index : this.elements[0].id
      );
  },

  computed: {
    selectElement() {
      if (this.value) {
        return this.getElementById(this.value);
      }
      return null;
    },
    selectElementName() {
      return this.service && this.serviceType
        ? getServiceName({
            ...this.selectElement,
            type_service: this.serviceType,
          })
        : this.selectElement.label;
    },
    disabled() {
      return this.elements.length <= 1;
    },
  },

  methods: {
    getElementById(id) {
      return this.elements.find(op => op.id === id);
    },
  },
};
</script>

<style scoped></style>
