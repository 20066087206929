<template>
  <div class="alert alert-dismissible" :class="alertClass">
    <button
      type="button"
      class="close p-2"
      data-dismiss="alert"
      aria-label="Close"
      v-on:click="closeAlert()"
    >
      <span aria-hidden="true"
        ><span class="f-16 font-weight-bold icon-close"></span
      ></span>
    </button>
    <strong class="d-block font-weight-bold" v-if="title">{{ title }}</strong>
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeAlert() {
      this.$emit("closeAlert");
    },
  },
  computed: {
    alertClass() {
      return "alert-bwallet-" + this.type;
    },
  },
};
</script>

<style scoped></style>
