export default {
  new_money_transfer: "Nouveau transfert d’argent",
  transfers_list: "Liste des transferts",
  transfer_details: "Details du transfert",
  new_transfer: "Nouveau transfert",
  of_money: "d'argent",
  received_amount: "Montant Reçu",
  receiver: "Bénéficiaire",
  receiver_1: "Récepteur",
  receiver_1_email: "Email du récepteur",
  sender: "Expéditeur",
  no_specified: "Non spécifié",
  transfer_fee: "Frais de transfert",
  amount: "Montant",
  operation_executed_at: "Opération exécuté le",
  amount_of: "Un montant de",
  total_debited: "Total débité",
  total_amount_debited: "Montant total débité",
  withdraw_in_account: "sera débité de votre compte",
  transfer_of: "Transfert de",
  to_account: "vers le compte",
  amount_without_tax: "Solde hors taxe après validation de l’opération",
  sending_amount: "Montant à envoyer",
  sending_amount_placeholder: "Entrez le montant",
  receiver_email: "Email du récepteur",
  receiver_email_placeholder: "Entrez l'email du recepteur",
  account_find: "Rechercher le compte",
  email_required: "L'email du recepteur est obligatoire",
  email_validated: "Veuillez saisir un email valide",
  email_self_validated:
    "Le transfert vers votre propre compte n'est pas autorisé",
  amount_required: "Veuillez saisir le montant du transfert",
  amount_must_be_low_than: "Le montant du transfert doit être inférieur à",
  amount_must_be_greater_than: "Le montant du transfert doit être supérieur à",
  operation_amount: "Montant de l'opération",
  payment_amount: "Montant du paiement",
  payment_amount_placeholder: "Entrer le montant du paiement",
  phone_number: "Numéro de téléphone",
  phone_number_placeholder: "Entrer le numéro de téléphone",
  no_transaction_at_the_moment:
    "Vous n’avez effectué aucune opération pour le moment.",
};
