import { render, staticRenderFns } from "./BaseCreateTicketLine.vue?vue&type=template&id=65d58e66&scoped=true&"
import script from "./BaseCreateTicketLine.vue?vue&type=script&lang=js&"
export * from "./BaseCreateTicketLine.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d58e66",
  null
  
)

export default component.exports