import store from "@/store";

const profile = [
  {
    path: "/",
    redirect: { name: "formProfile" },
  },
  {
    path: "edit",
    name: "profile",
    component: () => import("../views/profile/FormProfile"),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("getCountries").then(() => {
        next();
      });
    },
  },
  {
    path: "configurations",
    name: "formSetting",
    component: () => import("../views/profile/FormSetting"),
  },
  {
    path: "change-password",
    name: "formPassword",
    component: () => import("../views/profile/FormPassword"),
  },
];

export default profile;
