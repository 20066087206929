<template>
  <div
    class="d-flex flex-fill align-items-center justify-content-center flex-column no-cont p-4"
  >
    <i :class="icon" />
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "NoContent",
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.no-cont {
  color: #868b94;
}
.no-cont > i {
  font-size: 120px;
  color: #e1e4ea;
}
.no-cont > span {
  margin-top: 40px;
  text-align: center;
}
</style>
