export default {
  login_message: "Authentification effectue avec success.",
  cant_login_admin: "Impossible de se connecter avec un compte administrateur",
  menu: {
    identify: "S’IDENTIFIER",
    sign_in: "Se connecter",
    log_in: "Connexion",
    register: "S’INSCRIRE",
  },
  user_conditions: "Conditions d’utilisation",
  identify_me: "S’identifier",
  new_on_bewallet: "Nouveau sur BeWallet ?",
  password_forgot: "Mot de passe oublié ?",
  register_you: "Inscrivez-vous",
  register_me_desc_before:
    "En m’enregistrant, je confirme avoir lu et accepter les",
  register_me_desc_after:
    "de BeWallet. À l’occasion, nous envoyons des sms de notification payants " +
    "qui peuvent être désactivés dans les paramètres de compte.",
  register_me: "M'enregistrer",
  did_you_have_an_account: "Vous avez-déja un compte?",
  register_successful: "Enregistré avec succès",
  register_successful_desc:
    "Veuillez vérifier votre e-mail, nous avons envoyé un e-mail de confirmation pour confirmer votre compte.",
  errors: {
    country: {
      required: "Le champs pays est requis",
    },
    email: {
      required: "Le champs e-mail est requis",
      email: "Le champs e-mail n'est pas valide",
    },
    password: {
      required: "Le mot de passe est requis",
      min_length: "Au moins 6 caractères",
      contains_uppercase: "Au moins une lettre en majuscule",
      contains_number: "Au moins un chiffre",
    },
    password_confirmation: {
      required: "La confirmation du mot de passe est requise",
      same_as_password: "La confirmation du mots de passe ne correspondent pas",
    },
  },
  fields: {
    remember_me: "Se souvenir de moi",
    email: {
      placeholder: "Adresse email",
    },
    password: {
      placeholder: "Mot de passe",
    },
    confirm_password: {
      placeholder: "Confirmer le mot de passe",
    },
    country: {
      placeholder: "Votre Pays",
    },
    required: "Veuillez remplir correctement les champs.",
  },
  recover_account: "Récupération du compte",
  recover_account_desc:
    "Entrez l'adresse e-mail vérifiée de votre compte utilisateur et nous vous enverrons un lien " +
    "de réinitialisation du mot de passe.",
  identify: "S’identifier",
  back_to_before: "Retourner à la page de",
  reinitialize: "Réinitialiser",
  confirm: "Confirmer",
  confirmation: "Confirmation",
  new_password: "Nouveau mot de passe",
  password: "Mot de passe",
  confirm_password: "Confirmer le mot de passe",
  click_here_to_send: "Cliquez ici pour renvoyer.",
  click_here_to_send_new: "Cliquez ici pour envoyer un nouveau lien.",
  need_a_new_mail: "Besoin d'un nouveau lien de confirmation ?",
  new_email_sent: "Nouveau mail envoyé avec succès",
  email_sent: "Email envoyé",
  email_sent_desc:
    "Veuillez vérifier votre messagerie, nous avons envoyé un e-mail pour récupérer votre compte.",
  email_sent_desc_1:
    "Un mail de confirmation vous a été envoyé, Veillez-vous connectez à votre boîte de messagerie pour confirmer.",
};
