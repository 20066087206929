<template>
  <div class="be-input-search">
    <input
      :disabled="disabled"
      :class="{
        disabled: disabled,
        'w-100': true,
      }"
      type="text"
      :value="value"
      @input="onInput"
      :placeholder="$t('common.find')"
    />
    <span class="icon-for-input" />
  </div>
</template>

<script>
export default {
  name: "BaseInputSearch",
  props: {
    value: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  methods: {
    onInput(event) {
      this.$emit("search", event.target.value);
      this.$emit("input", event.target.value);
    },
    cancelSearch() {
      this.$emit("cancel");
    },
  },
  beforeDestroy() {
    if (this.value) this.$emit("cancel");
  },
};
</script>

<style scoped>
.disabled {
  background-color: white;
  border: 1px dashed #e1e4ea;
  border-radius: 25px;
  cursor: not-allowed;
}
</style>
