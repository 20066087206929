export const formFieldMixin = {
  inheritAttrs: false,

  props: {
    label: { type: String, default: "" },
    value: [String, Number],
    rightIconClass: { type: String, default: "" },
    leftIconClass: { type: String, default: "" },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    isPhoneInput: {
      type: Boolean,
      default: false,
    },
    countryCode: {
      type: String,
      default: "",
    },
    rightText: {
      type: String,
      default: "",
    },
    errorMessage: {
      default: "",
    },
    description: {
      default: "",
    },
    disabled: {
      default: false,
    },
  },

  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
      this.$emit("change", event.target.value);
    },
  },
};
