<template>
  <div class="ticket-details pb-0">
    <span class="d-block pt-1 border-bottom pb-1" v-if="ticket.label">
      <b>{{ ticket.label }}</b></span
    >
    <div class="ticket-flex-1 d-flex flex-column">
      <div class="detail-title">{{ $t("common.updated_at") }}</div>
      <div>{{ ticket.updated_at | moment("Do/MM/YYYY H:m:s") }}</div>
    </div>
    <template v-if="ticket.ticket_form_values.length > 0">
      <ticket-form-value-element
        v-for="(ticketFormValue, index) in ticket.ticket_form_values"
        :ticketFormValue="ticketFormValue"
        :key="index"
      />
    </template>
    <template v-else>
      <span class="d-block pt-1 border-bottom pb-1" v-if="ticket.performed_at">
        <b>
          <i class="fas fa-calendar-check f-14" />
          {{ $t("transaction.operation_executed_at") }}
        </b>
        : {{ ticket.performed_at | moment("Do MMMM YYYY à H:m:s") }}</span
      >
      <div class="ticket-flex-1 d-flex flex-column" v-if="ticket.amount">
        <div>
          {{ $t("transaction.operation_amount") }}
        </div>
        <div>{{ `${ticket.amount} ${currency}` }}</div>
      </div>
      <span class="d-block pt-1 border-bottom pb-1" v-if="ticket.capture">
        <b>
          <i class="fas fa-image f-14" />
          {{ $t("assistance.sms_proof") }}
        </b>
        <a class="text-info" :href="ticket.capture" target="_blank">
          <span class="fas fa-external-link-alt m-r-5" />
          {{ $t("assistance.view_picture") }}
        </a>
      </span>
    </template>

    <template v-if="ticket.model">
      <div class="ticket-flex-1 d-flex flex-column" v-if="ticket.amount">
        <div class="detail-title">
          {{ $t("transaction.amount") }}
        </div>
        <div>{{ operationAmount }}</div>
      </div>
      <div
        class="ticket-flex-1 d-flex flex-column"
        v-if="ticket.type.code === 'numbers'"
      >
        <div class="detail-title">{{ $t("fields.phone.label") }}</div>
        <div>{{ ticket.model.phone }}</div>
      </div>
      <div class="ticket-flex-1 d-flex flex-column" v-else>
        <div class="detail-title">{{ $t("common.title") }}</div>
        <div>{{ operationReference }}</div>
      </div>
      <div class="ticket-flex-1 d-flex flex-column" v-if="ticket.model.status">
        <div class="detail-title">{{ $t("common.status") }}</div>
        <div :class="statusClass.text">
          {{ ticket.model.status }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TicketFormValueElement from "./TicketFormValueElement";
import { typeTicketCode } from "../../helpers/constants";
import { mapGetters } from "vuex";

export default {
  name: "TicketDetailHeaderInfos",
  components: { TicketFormValueElement },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["currency"]),
    operationAmount() {
      if (this.ticket && this.ticket.model)
        return this.ticket.model.base_amount + " " + this.ticket.model.currency;
      else return null;
    },
    operationReference() {
      if (this.ticket && this.ticket.model)
        switch (this.ticket.type.code) {
          case typeTicketCode.deposit:
            return this.ticket.model.reference;
          case typeTicketCode.withdraw:
            return this.ticket.model.client_transaction_id;
          case typeTicketCode.payment:
            return this.ticket.model.payment_id;
          default:
            return this.ticket.model.reference;
        }
      else return null;
    },
    statusClass() {
      if (this.ticket && this.ticket.model)
        switch (this.ticket.model.status) {
          case "Succès":
            return {
              text: "text-success",
              icon: "fas fa-thumbs-up",
            };
          case "Echec":
            return {
              text: "be-text-failure",
              icon: "fas fa-thumbs-down",
            };
          default:
            return {
              text: "text-info",
              icon: "fas fa-thumbs-half",
            };
        }
      return "";
    },
  },
};
</script>

<style>
.fade-enter {
  transform: translateY(10px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
