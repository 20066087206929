<template>
  <div class="be-popover-bottom" v-if="noteOptions.length">
    <div class="be-popover-header">
      <p>{{ $t("base.notifications") }}</p>
      <span> {{ noteOptions.length }} </span>
    </div>
    <hr />
    <div class="be-popover-center">
      <div
        class="row m-0"
        v-for="(notification, index) in noteOptions"
        :key="'mob-' + index"
      >
        <div class="d-flex ml-4 mr-4 be-row-content border-bottom">
          <span
            class="icon-info-fill be-text-primary"
            :class="notification.model"
          />

          <div class="col p-0">
            <div class="d-flex justify-content-between align-items-center">
              <p class="text-capitalize m-0 be-row-content-title">
                {{ notification.titre }}
              </p>
              <span class="note-time">
                <!-- <i class="icon feather icon-clock"></i> -->
                {{ notification.created_at | moment("from", "now") }}
              </span>
            </div>
            <p class="m-0 be-row-content-desc">
              {{ notification.label }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="be-popover-footer">
      <router-link :to="{ name: 'notification' }">
        {{ $t("base.all_notifications") }}
      </router-link>
    </div>
  </div>
  <div class="be-popover-bottom" v-else>
    <div class="be-popover-header">
      <p>{{ $t("base.notifications") }}</p>
    </div>
    <hr />
    <div class="be-popover-center" style="display: flex;">
      <NoContent :icon="'icon-bell'" :text="'No Data'" />
    </div>
    <hr />
    <div class="be-popover-footer">
      <router-link :to="{ name: 'notification' }">
        {{ $t("base.all_notifications") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import NoContent from "../common/NoContent";
export default {
  name: "NotificationsPopover",
  components: { NoContent },
  data() {
    return {};
  },
  props: {
    noteOptions: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped></style>
