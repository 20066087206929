<template>
  <div class="be-multiselect">
    <span
      v-if="inputLabel"
      class="search-input-label"
      :class="{ 'be-text-failure': errorMessage }"
    >
      <span class="f-w-600"> {{ inputLabel }}</span>
    </span>
    <div
      @click.stop="e => !disabled && toggle(e)"
      :class="{ disabled: disabled, 'bg-gray': dashboard }"
      class="select-input"
    >
      <i v-if="icon" class="icon-before-placeholder" :class="icon" />
      <div
        class="selected gradient-text text-truncate d-flex align-items-center justify-content-between w-100"
        v-if="selected && selectedValue"
      >
        {{ selectedValue }}
        <i v-if="!disabled" class="icon-arrow-down" />
      </div>
      <div v-else class="w-100">
        <div
          v-if="!dashboard"
          class="d-flex align-items-center justify-content-between"
        >
          <span>{{
            placeholder ? placeholder : $t("base.choose_service")
          }}</span>
          <i v-if="!disabled" class="icon-arrow-down" />
        </div>
        <div
          v-else
          class="gradient-text d-flex align-items-center justify-content-between f-17 f-w-700"
        >
          <span>{{ $t("base.buy_a_service") }}</span>
          <i class="icon-plus f-22" />
        </div>
      </div>
    </div>
    <div
      v-if="show"
      class="options"
      :class="{
        'with-images': withImages && (filteredOptions || []).length > 2,
        [this.uniqueClassName]: true,
      }"
    >
      <div v-if="searchable">
        <div
          class="search-input d-flex align-items-center justify-content-center"
        >
          <BaseInputSearch @search="handleSearch" size="sm" />
        </div>
        <div class="divider" />
      </div>
      <div class="options-list" :class="{ 'd-flex flex-wrap': withImages }">
        <div
          class="option"
          :class="{
            selected: option[trackBy] === (selected && selected[trackBy]),
            'img-border': withImages,
            disabled: option.disabledOption,
          }"
          v-for="(option, idx) in filteredOptions"
          :key="idx"
          :ref="
            option[trackBy] === (selected && selected[trackBy])
              ? 'selectedItem'
              : ''
          "
          @click="e => !option.disabledOption && chooseOption(e, option)"
        >
          <div
            v-if="!withImages"
            :class="{
              'd-flex justify-content-between align-items-center flex-fill':
                !multi && !withImages,
              'd-flex align-items-center': multi && !withImages,
            }"
          >
            <input
              v-if="multi"
              type="checkbox"
              :checked="
                selected && selected.find(s => s[trackBy] === option[trackBy])
              "
            />
            <div>
              {{ option[label] }}
            </div>
            <i
              v-if="
                !multi && option[trackBy] === (selected && selected[trackBy])
              "
              class="icon-success f-24"
            />
          </div>
          <div
            v-else-if="withImages"
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <!-- :src="require(`@/assets/landing/partners/${option.code}.png`)" -->
            <img
              v-if="option.logo"
              :src="option.logo"
              alt=""
              width="60"
              height="60"
            />
            <img
              v-else
              :src="getImg(option.label || 'notFound')"
              alt=""
              width="60"
              height="60"
            />
            <span class="f-12">
              {{ option[label] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="errorMessage" class="input-error-message">
      {{ errorMessage }}
    </div>
    <div v-if="description" class="input-description">
      {{ description }}
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { getImg } from "@/helpers/utils";

export default {
  name: "BeMultiselect",
  props: {
    value: {
      type: [Object, Array, Number, String],
      default: () => {},
    },
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    trackBy: {
      type: String,
      default: "",
    },
    multi: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    inputLabel: {
      type: String,
      default: "",
    },
    withImages: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    onlyKey: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: Object,
      default: () => {},
    },
    noChange: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
      selected: null,
      search: "",
      uniqueClassName: "",
      getImg,
    };
  },
  watch: {
    options() {
      if (!this.noChange) {
        this.selected = null;
        if (this.initialValue && this.initialValue[this.trackBy]) {
          const option = this.options.find(
            item => item[this.trackBy] === this.initialValue[this.trackBy]
          );
          if (!this.multi) {
            if (option) {
              this.selected = option;
            } else {
              this.selected = null;
            }
          }
        }
      }
    },
  },
  computed: {
    uOptions() {
      return _.uniqBy(this.options, this.trackBy);
    },
    filteredOptions() {
      return this.search
        ? this.uOptions.filter(op =>
            op[this.label].toLowerCase().includes(this.search.toLowerCase())
          )
        : this.uOptions;
    },
    selectedValue() {
      if (this.multi && this.selected?.length) {
        return this.selected.length === 1
          ? this.selected[0][this.label]
          : `${this.selected?.length} ` + this.$t("base.selected_options");
      } else if (this.multi && !this.selected.length) {
        return false;
      }
      return this.selected[this.label];
    },
  },
  created() {
    this.uniqueClassName = ~~(Math.random() * 1000) + this.$t("base.options");
    if (this.initialValue && this.initialValue[this.trackBy]) {
      const option = this.options.find(
        item => item[this.trackBy] === this.initialValue[this.trackBy]
      );
      if (!this.multi) {
        if (option) {
          this.selected = option;
        }
      }
    }
    document.body.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.close);
  },
  methods: {
    handleSearch(text) {
      this.search = text;
    },
    chooseOption(e, option) {
      e && e.stopPropagation();
      if (!this.multi) {
        // single select
        if (
          this.selected &&
          this.selected[this.trackBy] === option[this.trackBy]
        ) {
          this.selected = null;
          this.$emit("input", null);
          return;
        } else {
          this.selected = option;
          if (this.closeOnSelect) this.show = !this.show;
        }
        if (this.onlyKey) {
          this.$emit("input", option[this.trackBy]);
        } else {
          this.$emit("input", option);
        }
      } else {
        // multiple select
        if (
          this.selected &&
          this.selected.find(s => s[this.trackBy] === option[this.trackBy])
        ) {
          this.selected = this.selected.filter(
            s => s[this.trackBy] !== option[this.trackBy]
          );
        } else {
          this.selected = [...(this.selected || []), option];
          if (this.closeOnSelect) this.show = !this.show;
        }
        this.$emit("input", this.selected);
      }
    },
    toggle(e) {
      e.stopPropagation();
      const clonedNativeEvent = new MouseEvent("click", e);
      document.getElementsByTagName("body")[0].dispatchEvent(clonedNativeEvent);
      this.show = !this.show;
      if (this.show) {
        this.$nextTick(() => {
          const selectedItem = this.$refs.selectedItem;
          if (selectedItem && selectedItem[0]) {
            selectedItem[0].parentNode.scrollTop = selectedItem[0].offsetTop;
          }
        });
      }
    },

    hasSomeParentTheClass(element, className) {
      if (element.className?.split(" ").indexOf(className) >= 0) return true;
      return (
        element.parentNode &&
        this.hasSomeParentTheClass(element.parentNode, className)
      );
    },
    close(e) {
      e.stopPropagation();
      if (!this.hasSomeParentTheClass(e.target, this.uniqueClassName)) {
        this.show = false;
      }
    },
  },
};
</script>

<style scoped></style>
