<template>
  <div class="form-group">
    <div
      class="input-label f-w-600"
      v-if="!!label"
      :class="{ 'hljs-strong': bold, 'input-danger': errorMessage }"
    >
      {{ label }}
    </div>
    <div
      class="input-group position-relative"
      :class="{ leftIconInactive: !value }"
    >
      <i v-if="leftIconClass" v-bind:class="[leftIconClass, 'input-icon']"></i>
      <input
        v-on="listeners"
        v-bind="$attrs"
        :type="isVisible ? 'text' : 'password'"
        :value="value"
        :class="{
          'is-invalid': isInvalid,
          wInput: true,
          hasLeftIcon: !!leftIconClass,
          'pr-5': true,
        }"
        @input="updateValue"
      />
      <i
        class="visible-image"
        :class="{
          'icon-eye': !isVisible,
          'icon-eye-off': isVisible,
        }"
        @click.prevent="setVisible"
      ></i>
    </div>
    <div v-if="errorMessage" class="input-error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { formFieldMixin } from "../../mixins/formFieldMixin";

export default {
  name: "BaseInputPassword",
  mixins: [formFieldMixin],
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
  },
  methods: {
    setVisible() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<style scoped>
.visible-image {
  position: absolute;
  right: 24px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  margin-top: 16px;
  width: 20px;
  height: 20px;
}
.input-icon {
  position: absolute;
  font-size: 24px;
  left: 22px;
  top: 14px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
</style>
