<template>
  <div class="row align-items-center justify-content-between m-0">
    <div class="be-alert-item-info">
      <p>{{ $t("common.attention") }}</p>
      <p>{{ message }}</p>
    </div>
    <div class="be-alert-item-link">
      <router-link :to="{ name: routerName }" v-if="routerName">
        {{ routerTitle }}
      </router-link>
    </div>
    <!-- <div class="col-12 col-lg-3 text-right mt-2 mt-sm-0" >

      </div> -->
  </div>
</template>

<script>
export default {
  name: "GlobalAlertItem",
  props: {
    message: {
      type: String,
      required: true,
    },
    routerName: {
      type: String,
      default: null,
    },
    routerTitle: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
}

.be-alert-item-info {
  > p {
    word-break: break-all;
  }
  > p:first-child {
    font-weight: 600;
    font-size: 16px;
    color: #0b1527;
    margin-bottom: 5px;
    @media (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 12px;
    }
  }
  > p:last-child {
    @media (max-width: 768px) {
      line-height: 26px;
    }

    font-weight: normal;
    font-size: 14px;
    color: #0b1527;
    margin: 0;
  }
}
.be-alert-item-link {
  @media (max-width: 768px) {
    margin-top: 20px;
  }
  > a {
    @media (max-width: 768px) {
      width: 310px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 226px;
    height: 42px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #0071ff;
    background-color: rgba(0, 113, 255, 0.1);
    border-radius: 21px;
  }
}
</style>
