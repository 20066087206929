import axios from "axios";

export default {
  getCountries() {
    return axios.get("/countries/inscription");
  },
  checkEndOfMaintenanceMode() {
    return axios.get("/check-end-of-maintenance");
  },
};
