import ShoppingService from "../../services/ShoppingService";
import {
  getReferenceValue,
  isPriceConfirmation,
  travelTimeIsNight,
} from "../../helpers/utils";

const state = {
  serviceLoading: false,
  services: [],
  metaShopping: null,
  typeFilter: null,
  cities: [],
  agencies: [],
  passengers: [],
  allUserService: [],
  canalPlusProducts: [],
  bills: [
    {
      index: 0,
      due_date: 1604188800,
      bill_number: "478347377",
      penal_amount: 0,
      amount: 4150,
    },
    {
      index: 1,
      due_date: 1606867200,
      bill_number: "484894137",
      penal_amount: 0,
      amount: 3900,
    },
    {
      index: 2,
      due_date: 1610064000,
      bill_number: "496143082",
      penal_amount: 0,
      amount: 4850,
    },
  ],
  serviceLanding: [],
};

const getters = {
  shoppings: state => (state.metaShopping ? state.metaShopping.data : []),
  current_page: state =>
    state.metaShopping ? state.metaShopping.current_page : 1,
  last_page: state => (state.metaShopping ? state.metaShopping.last_page : 1),
  activeServices: state => state.services.filter(s => s.is_active),
  getServicesByTypeId: state => id =>
    state.services.filter(s => s.type_service.id === id),
  service_types: state => {
    let types = [];
    state.services
      .filter(s => s.is_active)
      .forEach(elt => {
        if (types.findIndex(t => t.id === elt.type_service.id) === -1)
          types.push(elt.type_service);
      });
    return types.length > 0 ? types : false;
  },
  filterTravels: state => (trip, day) => {
    if (state.agencies.length > 0) {
      let travels = [];
      state.agencies.forEach(a => {
        if (a.state) travels = [...travels, ...a.voyages];
      });
      if (trip)
        travels = travels.filter(
          t => t.standing_trip.toString().toLowerCase() === trip
        );
      if (day)
        travels = travels.filter(t => {
          if (day === "night") return travelTimeIsNight(t);
          else return !travelTimeIsNight(t);
        });
      return travels.sort((a, b) => {
        let dateA = new Date(a.date + " " + a.departure_time);
        let dateB = new Date(b.date + " " + b.departure_time);
        return dateA - dateB;
      });
    } else return [];
  },
};

const actions = {
  getServiceList({ commit, state }, { page, field }) {
    commit("SET_SERVICE_LOADER", true);
    if (state.services.length > 0) {
      commit("SET_SERVICE_LOADER", false);
      return state.services;
    } else {
      return ShoppingService.getServiceList(page, field)
        .then(({ data }) => {
          commit("SET_SERVICES", data);
          commit("SET_SERVICE_LOADER", false);
          return data;
        })
        .catch(err => {
          commit("SET_SERVICE_LOADER", false);
          return Promise.reject(err);
        });
    }
  },
  getShoppingList({ commit, state }, { page, field, cancel, filter }) {
    if (
      state.metaShopping &&
      page === state.metaShopping.current_page &&
      !field.search &&
      !cancel &&
      !filter
    )
      return state.metaShopping;
    else {
      if (filter) commit("SET_TYPE_FILTER", field.type);
      else commit("SET_TYPE_FILTER", null);
      if (field.search)
        return ShoppingService.searchFromShoppingList(page, field).then(
          response => {
            commit("SET_META_SHOPPING", response.data);
          }
        );
      else
        return ShoppingService.getShoppingList(page, field).then(response => {
          commit("SET_META_SHOPPING", response.data);
        });
    }
  },
  getShopping({ getters, dispatch }, id) {
    const shopping = getters.shoppings.find(s => s.id === id);
    if (shopping !== undefined) return shopping;
    else
      return ShoppingService.getShopping(id)
        .then(({ data }) => {
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  getServiceTypeByCode({ getters, dispatch }, code) {
    if (getters.service_types.length > 0) {
      const type = getters.service_types.find(
        st => st.code.toLowerCase() === code
      );
      return {
        type: type,
        services: getters.getServicesByTypeId(type.id),
      };
    } else
      return ShoppingService.getServiceTypeByCode(code)
        .then(({ data }) => {
          return {
            type: data,
            services: data.user_services,
          };
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  getServiceByIndex({ state, commit, dispatch }, index) {
    if (index) {
      const service = state.services.find(s => s.index.toLowerCase() === index);
      if (service !== undefined && service.user_services) return service;
      else
        return ShoppingService.getServiceByIndex(index)
          .then(({ data }) => {
            commit("UPDATE_SERVICE_IN_LIST", data);
            return data;
          })
          .catch(err => {
            if (err.response.data.message) {
              const notification = {
                type: "danger",
                message: err.response.data.message,
                is_toast: true,
              };
              dispatch("notification/add", notification, { root: true });
            }
            return Promise.reject(err.response.data);
          });
    } else return null;
  },
  getTravelCities({ commit, state }) {
    if (state.cities.length > 0) return state.cities;
    else {
      return ShoppingService.getTravelCities().then(({ data }) => {
        const cities = data.sort((a, b) => (a.label > b.label ? 1 : -1));
        commit("SET_CITIES", cities);
        return cities;
      });
    }
  },
  searchTravels({ commit, dispatch }, { code, field }) {
    return ShoppingService.searchTravels(code, field)
      .then(({ data }) => {
        if (data.length > 0) commit("SET_AGENCIES", data);
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  changeAgencySate({ commit }, index) {
    commit("CHANGE_AGENCY_STATE", index);
  },

  getTravelTripByToken({ getters }, token) {
    const travels = getters.filterTravels(null, null);
    const travel = travels.find(t => t.token_trip === token);
    if (travel !== undefined) return travel;
    else return Promise.reject(null);
  },

  getPassengers({ commit, state }) {
    if (state.passengers.length > 0) return state.passengers;
    else {
      return ShoppingService.getPassengers().then(({ data }) => {
        commit("SET_PASSENGERS", data);
        return data;
      });
    }
  },

  addPassenger({ commit }, passenger) {
    return ShoppingService.addPassenger(passenger).then(({ data }) => {
      commit("ADD_PASSENGER", data);
      return data;
    });
  },

  processTravelPayment({ commit, dispatch }, { index, passengers, travel }) {
    // if (rootGetters["auth/isCompleteProfile"]) {
    return ShoppingService.processTravelPayment({
      code: index,
      passengers: JSON.stringify(passengers),
      voyage: JSON.stringify(travel),
    })
      .then(({ data }) => {
        commit("ADD_SHOPPING", data);
        const notification = {
          type: "success",
          message:
            "Votre voyage a été reservé avec succès. Vous allez recevoir " +
            "par SMS un code à présenter à l'agence.,",
          is_toast: false,
        };
        dispatch("notification/add", notification, { root: true });
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
    // } else
    //   return Promise.reject({
    //     data: {
    //       message: "Veuillez compléter votre profil pour effectuer le paiement."
    //     }
    //   });
  },

  addUserService({ commit, dispatch }, { service, userService }) {
    return ShoppingService.addUserService({
      service: service.code,
      remember: userService.remember,
      reference: userService.reference,
    }).then(({ data }) => {
      const userService = data.user_services[data.user_services.length - 1];
      commit("UPDATE_SERVICE", { service, userService });
      const notification = {
        type: "success",
        message: "Opération effectuée avec succès.",
        is_toast: true,
      };
      dispatch("notification/add", notification, { root: true });
      return userService;
    });
  },

  updateUserService({ commit, dispatch }, { service, userService }) {
    return ShoppingService.updateUserService(userService)
      .then(({ data }) => {
        commit("UPDATE_USER_SERVICE", { service, userService });
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return userService;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  getCanalPlusProductList({ commit, state }) {
    if (state.canalPlusProducts.length > 0) return state.canalPlusProducts;
    else
      return ShoppingService.getCanalPlusProductList().then(({ data }) => {
        commit("SET_CANAL_PLUS_PRODUCTS", data);
        return data;
      });
  },

  searchBills({ commit, dispatch }, { code, reference }) {
    return ShoppingService.searchBills(code, reference)
      .then(({ data }) => {
        commit("SET_BILLS", data);
        const notification = {
          type: "info",
          message: `Nous avons trouvé ${data.length} facture${
            data.length > 1 ? "s" : ""
          } impayée${data.length > 1 ? "s" : ""}`,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
        if (data.length === 1)
          return dispatch("quoteShopping", {
            serviceCode: code,
            quoteInput: {
              ...data[0],
              svcNmb: reference,
            },
          });
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  quoteShopping({ dispatch }, { serviceCode, quoteInput }) {
    // if (rootGetters["auth/isCompleteProfile"]) {
    return ShoppingService.quoteShoppingRequest(serviceCode, quoteInput)
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
    // } else
    //   return Promise.reject({
    //     data: {
    //       message: "Veuillez compléter votre profil acheter un service."
    //     }
    //   });
  },

  confirmShopping(
    { dispatch, commit },
    { service, userService, quote, visaCard }
  ) {
    const reference = getReferenceValue(
      service.type_service.code,
      userService.reference
    );
    let quoteData = { ...quote };
    if (isPriceConfirmation(service)) quoteData.amount = quoteData.price;
    return ShoppingService.confirmShoppingRequest(service.code, {
      users_services_id: userService.id,
      ...quoteData,
      svc_number: reference,
      visaCard: visaCard ? JSON.stringify(visaCard) : null,
    })
      .then(({ data }) => {
        commit("ADD_SHOPPING", data.shopping);
        const notification = {
          type: "success",
          message:
            "Opération effectuée avec succès. Temps de traitement: 7 minutes maximum",
          is_toast: false,
        };
        dispatch("notification/add", notification, { root: true });
        dispatch("auth/getCurrentUser", null, { root: true });
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  SET_META_SHOPPING(state, metaShopping) {
    state.metaShopping = metaShopping;
  },
  ADD_SHOPPING(state, shopping) {
    if (state.metaShopping) state.metaShopping.data.unshift(shopping);
  },
  SET_SERVICES(state, services) {
    state.services = services;
  },
  UPDATE_SERVICE_IN_LIST(state, service) {
    if (state.services.length > 0) {
      let index = state.services.findIndex(s => s.id === service.id);
      if (index !== -1) state.services.splice(index, 1, service);
    }
  },
  SET_LANDING_SERVICES(state, serviceLanding) {
    state.serviceLanding = serviceLanding;
  },
  SET_SERVICE_LOADER(state, serviceLoading) {
    state.serviceLoading = serviceLoading;
  },
  SET_TYPE_FILTER(state, type) {
    state.typeFilter = type;
  },
  SET_CITIES(state, cities) {
    state.cities = cities;
  },
  SET_AGENCIES(state, agencies) {
    state.agencies = agencies;
  },
  UPDATE_SERVICE(state, { service, userService }) {
    service.user_services.push(userService);
  },
  CHANGE_AGENCY_STATE(state, index) {
    state.agencies[index].state = !state.agencies[index].state;
  },
  SET_PASSENGERS(state, passengers) {
    state.passengers = passengers;
  },
  ADD_PASSENGER(state, passenger) {
    if (state.passengers) state.passengers.unshift(passenger);
  },
  UPDATE_USER_SERVICE(state, { service, userService }) {
    let ser = state.services.find(s => s.id === service.id);
    if (ser !== undefined) {
      const userServiceIndex = ser.user_services.findIndex(
        us => us.id === userService.id
      );
      if (userServiceIndex >= 0) {
        service.user_services.splice(userServiceIndex, 1, userService);
      }
    }
  },
  SET_CANAL_PLUS_PRODUCTS(state, list) {
    state.canalPlusProducts = list;
  },
  SET_BILLS(state, bills) {
    state.bills = bills;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
