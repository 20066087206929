<template>
  <span class="d-block pt-1 border-bottom pb-1">
    <b
      ><i :class="labelIcon" class="f-14" />
      {{ ticketFormValue.ticket_form.label }}
    </b>
    : <span v-if="isText">{{ ticketFormValue.valeur }}</span>
    <span v-if="isDate">{{
      ticketFormValue.valeur | moment("Do MMMM YYYY à H:m:s")
    }}</span>
    <span v-if="isImage">
      <a class="text-info" :href="ticketFormValue.valeur" target="_blank">
        <span class="fas fa-external-link-alt m-r-5" />
        {{ $t("assistance.view_picture") }}
      </a>
    </span>
  </span>
</template>

<script>
export default {
  name: "TicketFormValueElement",
  props: {
    ticketFormValue: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isDate() {
      return (
        this.ticketFormValue.ticket_form.type === "datetime" ||
        this.ticketFormValue.ticket_form.type === "date"
      );
    },
    isImage() {
      return this.ticketFormValue.ticket_form.type === "image";
    },
    isText() {
      return !this.isDate && !this.isImage;
    },
    labelIcon() {
      switch (this.ticketFormValue.ticket_form.type) {
        case "numeric":
          return "fas fa-phone";
        default:
          return this.isDate
            ? "fas fa-calendar-check"
            : this.isImage
            ? "fas fa-image"
            : "fas fa-angle-double-right";
      }
    },
  },
};
</script>

<style scoped></style>
