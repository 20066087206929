export default {
  low_rate: "Taux faible",
  low_rate_desc:
    "Appliqué lorsque vous retirez un montant que Vous avez utilisé chez un marchand (Exemple: vous avez parié et gagné chez betmomo).",
  high_rate: "Taux faible",
  high_rate_desc:
    "Appliqué lorsque vous retiré un montant non utilisé. (Exemple : vous rechargez votre compte BeWallet et tentez de faire un retrait sans payer chez un marchand).",
  money_withdraw: "Retrait d'argent",
  new_money_withdraw: "Nouveau retrait d'argent",
  new_money_withdraw_fees: "Nouveau frais retrait d'argent",
  withdraw_amount: "Montant débité",
  withdraw_details: "Détails retrait",
  withdraw_list: "Liste des retraits",
  new_withdraw: "Nouveau retrait",
  no_withdraw_done: "Vous n’avez effectué aucun retrait d’argent.",
  enter_password_to_confirm_withdraw:
    "Entrez votre mot de passe pour confirmer le retrait",
  withdraw_amount_in_your_account_before:
    "Votre solde sera débité d'un montant de",
  insert_amount: "Veuillez saisir le montant du retrait",
  require_valid_amount: "Le montant du retrait doit être un multiple de 100",
  lowest_amount_required: "Le montant du retrait doit être inférieur à",
  greatest_amount_required: "Le montant du retrait doit être supérieur à",
};
