import assistance from "./assistance";
import auth from "./auth";
import base from "./base";
import common from "./common";
import dashboard from "./dashboard";
import deposit from "./deposit";
import menu from "./menu";
import landing from "./landing";
import layout from "./layout";
import payment from "./payment";
import profile from "./profile";
import shopping from "./shopping";
import withdraw from "./withdraw";
import transaction from "./transaction";

export default {
  assistance,
  auth,
  base,
  common,
  dashboard,
  deposit,
  menu,
  landing,
  layout,
  profile,
  payment,
  shopping,
  transaction,
  withdraw,
  copyright: {
    reserved: "Tous droits reservés.",
    build_by: "Développé par",
  },
  lang: {
    french: "Français",
    english: "English",
  },
  fields: {
    gender: {
      female: "Féminin",
      male: "Masculin",
    },
    phone: {
      label: "Téléphone",
      placeholder: "Entrez le numéro de téléphone",
    },
    email: {
      label: "Email",
      placeholder: "Entrez l'email",
    },
    password: {
      label: "Mot de passe",
      placeholder: "Entrez le mot de passe",
    },
    surname: {
      label: "Prénom",
      placeholder: "Entrez le prénom",
    },
    name: {
      label: "Nom",
      placeholder: "Entrez le nom",
    },
    otp: {
      label: "Code de paiement",
      placeholder: "Entrez le code du paiement",
    },
    country: {
      label: "Pays",
      placeholder: "Choisir le Pays",
    },
    operator: {
      label: "Opérateur",
      placeholder: "Choisir l'opérateur",
      empty: "Aucun opérateur disponible",
    },
    amount: {
      label: "Montant",
      placeholder: "Entrez le montant",
    },
    reference: {
      label: "Référence",
      placeholder: "Entrez la référence",
    },
    website: {
      label: "Site web",
      placeholder: "Entrez l'url du site web",
    },
  },
  countries: {
    cm: "Cameroun",
    bj: "Bénin",
    ga: "Gabon",
    gh: "Ghana",
    gn: "Guinée",
    ci: "Côte d'Ivoire",
    ml: "Mali",
    sn: "Sénégal",
    tg: "Togo",
    bf: "Burkina Faso",
  },
  utils: {
    page_not_found: "Page introuvable",
    go_to_home_page: "Aller à la page d’accueil",
    oops: "Oops !",
    current_maintenance_time: "Maintenance en cours",
    came_later_after_maintenance:
      "Veuillez patienter le système est en cours de maintenance",
    no_service: "Pas de services",
    place: "Place",
    places: "Places",
    send_code: "Envoyer un nouveau code",
    new_code_in: "Nouveau code dans",
    required: {
      name: "Veuillez saisir le nom",
      surname: "Veuillez saisir le prénom",
      number: "Veuillez saisir le numéro de téléphone",
      numbers_length_before: "La valeur du téléphone doit être ",
    },
    character: "caractère",
    characters: "caractères",
    fees: "Frais",
    tax_low: "Taux faible",
    tax_great: "Taux fort",
    do_reclamation: "Faire une reclamation",
    history: "Historique",
    dashboard: "Tableau de bord",
    transfer: "Transfert",
    transfers: "Transferts",
    recharges: "Recharges",
    recharge: "Recharge",
    withdrawals: "Retraits",
    withdrawal: "Retrait",
    deposit: "Depot",
    payment: "Paiement",
    the_value_of: "La valeur du",
    must_be: "doit être",
    travel_tickets: "Ticket de voyage",
    buy_a_service: "Acheter un service",
    visa_purchase: "Achat VISA",
    visa_recharge: "Recharge VISA",
    details: "Détails",
  },
  transfer: {
    transfer_of: "Transfert de",
  },
};
