import Vue from "./mainVue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "nprogress/nprogress.css";

import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import DateFilter from "./filters/date";
Vue.filter("date", DateFilter);
import TruncateFilter from "./filters/truncate";
Vue.filter("truncate", TruncateFilter);

import numFormat from "vue-filter-number-format";
import numeral from "numeral";
Vue.filter("numFormat", numFormat(numeral));

import VueContentPlaceholders from "vue-content-placeholders";
Vue.use(VueContentPlaceholders);

import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
Vue.use(VueIziToast);

import VueClipboards from "vue-clipboards";
Vue.use(VueClipboards);

import VueAnalytics from "vue-analytics";
//and then use it in main.js
Vue.use(VueAnalytics, {
  id: "UA-134331671-1",
  router,
});

import VueMoment from "vue-moment";
const moment = require("moment");
require("moment/locale/fr");
Vue.use(VueMoment, { moment });

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import VueScrollactive from "vue-scrollactive";
Vue.use(VueScrollactive);

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight,
  contentWidth:
    document
      ?.getElementsByClassName("pcoded-content")[0]
      ?.getBoundingClientRect().width || 0,
});

Vue.config.productionTip = process.env.NODE_ENV !== "production";

import { getUserSession } from "./helpers/utils";

window.addEventListener("resize", () => {
  Vue.prototype.$screen.width = window.innerWidth;
  Vue.prototype.$screen.height = window.innerHeight;
  Vue.prototype.$screen.contentWidth =
    document
      ?.getElementsByClassName("pcoded-content")[0]
      ?.getBoundingClientRect().width || 0;
});

require("./config/global");
require("./config/assets");
require("./config/axios");
require("./config/echo");

new Vue({
  router,
  store,
  created() {
    const userData = getUserSession("current_user_credentials");
    if (userData) {
      this.$store.commit("auth/SET_USER_TOKEN", userData);
    }
    this.$store.state.$t = this.$t;
  },
  render: h => h(App),
}).$mount("#app");
