import DepositService from "../../services/DepositService";
import OperatorService from "../../services/OperatorService";
import axios from "axios";

const state = {
  metaDeposit: null,
  operators: [],
  items: [],
  pageNumber: 0,
  pageSize: null,
  totalPages: 0,
  totalRecords: 0,
};

const getters = {
  // deposits: state => (state.metaDeposit ? state.metaDeposit.data : []),
  // current_page: state =>
  //   state.metaDeposit ? state.metaDeposit.current_page : 1,
  // last_page: state => (state.metaDeposit ? state.metaDeposit.last_page : 1),
  operators: state => state.operators.filter(op => op.is_depot),
  isDepositAvailable: state =>
    state.operators.find(op => op.is_depot) !== undefined,
  getOperatorById: state => id => {
    return state.operators.find(op => op.id === id);
  },
};

const actions = {
  async getDepositsList({ commit }, { params = {}, assignToken = () => {} }) {
    await axios
      .post(
        `/depots/list/search?page=${params.page}`,
        {
          ...params.field,
        },
        {
          cancelToken: new axios.CancelToken(c => {
            if (assignToken) {
              assignToken(c);
            }
          }),
        }
      )
      .then(
        ({ data }) => {
          commit("GET_DEPOSITS_LIST", data);
        },
        err => {
          if (axios.isCancel(err)) {
            return Promise.reject({ err, canceled: true });
          }
          return Promise.reject(err);
        }
      )
      .catch(err => Promise.reject(err));
  },

  // getDepositsList(context, { page, field }) {
  //   axios.post('/depots/list/search?page=' + page, { field, assignToken = () => {}});
  //   console.log(page, field);
  // if (
  //   state.metaDeposit &&
  //   page === state.metaDeposit.current_page &&
  //   !field.search &&
  //   !cancel
  // )
  //   return state.metaDeposit;
  // else {
  //   if (field.search)
  //     return DepositService.searchFromDepositList(page, field).then(
  //       response => {
  //         commit('SET_META_DEPOSIT', response.data);
  //       }
  //     );
  //   else
  //     return DepositService.getDepositList(page, field).then(response => {
  //       commit('SET_META_DEPOSIT', response.data);
  //     });
  // }
  // },
  getDeposit({ getters, dispatch }, id) {
    const deposit = getters.deposits.find(w => w.id === id);
    if (deposit !== undefined) return deposit;
    else
      return DepositService.getDeposit(id)
        .then(({ data }) => {
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  getDepositOperators({ commit, state }) {
    if (state.operators.length > 0) return state.operators;
    else
      return OperatorService.getDepositOperators().then(({ data }) => {
        commit("SET_OPERATORS", data);
      });
  },
  getDepositTaxAmountDetail({ dispatch }, { operator, amount }) {
    return DepositService.getApiTaxDetail({
      amount,
      api_code: operator.api_depot,
      operator_code: operator.code,
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response);
      });
  },
  initiateDeposit({ commit, dispatch, state }, { operator_id, amount, form }) {
    return DepositService.initiateDeposit({
      ...form,
      amount,
      operators_id: operator_id,
    })
      .then(response => {
        if (response.status === 200) {
          const notification = {
            type: "success",
            message: response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        if (response.data.deposit && state.metaDeposit)
          commit("ADD_DEPOSIT", response.data.deposit);

        return response;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response);
      });
  },
  verifyDeposit({ commit, dispatch, state }, id) {
    return DepositService.verifyDeposit(id)
      .then(response => {
        switch (response.status) {
          case 200: {
            if (state.metaDeposit)
              commit("UPDATE_DEPOSIT", response.data.deposit);
            const notification = {
              type: "success",
              message: response.data.message,
              is_toast: false,
            };
            dispatch("auth/getCurrentUser", null, { root: true });
            dispatch("notification/add", notification, { root: true });
            break;
          }
        }
        return response;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response);
      });
  },

  setOperatorNumber(
    { commit, dispatch },
    { operator, number, code, signature }
  ) {
    if (!code && !signature)
      return OperatorService.addOperatorNumber({
        operators_id: number.operators_id,
        phone: number.phone,
      })
        .then(({ data }) => {
          if (operator.number.limit)
            commit("ADD_OPERATOR_NUMBER", { number: data.number, operator });
          else commit("SET_OPERATOR_NUMBER", { number: data.number, operator });
          if (data.number.is_confirm) {
            const notification = {
              type: "success",
              message: `Votre numéro de recharge ${operator.label} a été mis à jour !`, //todo mettre a jour la traduction du message
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response);
        });
    else {
      return OperatorService.updateOperatorNumber({
        ...number,
        code,
        signature,
        is_confirm: !!code,
      })
        .then(({ data }) => {
          if (operator.number.limit)
            commit("ADD_OPERATOR_NUMBER", { number: data.number, operator });
          else commit("SET_OPERATOR_NUMBER", { number: data.number, operator });
          if (data.number.is_confirm) {
            const notification = {
              type: "success",
              message: this.state.$t(
                "deposit.recharge_number_has_been_updated",
                { label: operator.label }
              ),
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response);
        });
    }
  },
};

const mutations = {
  GET_DEPOSITS_LIST(state, { data, current_page, total, per_page, last_page }) {
    state.items = data;
    state.pageNumber = current_page;
    state.pageSize = per_page;
    state.totalPages = last_page;
    state.totalRecords = total;
  },
  SET_META_DEPOSIT(state, metaDeposit) {
    state.metaDeposit = metaDeposit;
  },
  SET_OPERATORS(state, operators) {
    state.operators = operators;
  },
  ADD_DEPOSIT(state, deposit) {
    if (state.metaDeposit) state.metaDeposit.data.unshift(deposit);
  },
  UPDATE_DEPOSIT(state, deposit) {
    state.metaDeposit.data.splice(d => d.id === deposit.id, 1, deposit);
  },

  SET_OPERATOR_NUMBER(state, { number, operator }) {
    operator.number.data = number;
  },

  ADD_OPERATOR_NUMBER(state, { number, operator }) {
    if (number.is_confirm) operator.number.numbers.push(number);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
