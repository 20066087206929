export default {
  balance: "Solde de votre compte",
  withdraw: "Faire un retrait",
  complaints: "Mes réclamations",
  tickets: "TICKETS",
  open_ticket: "Ouvrir un ticket",
  save_contact: "Voulez-vous vraiment enregistrer ce numéro pour vos dépôts ?",
  add_recharge_number: "Ajout d'un numéro autorisé pour vos recharges",
  config_recharge_number: "Configuration du numéro autorisé pour vos recharges",
  update_recharge_number: "Modification du numéro de recharge",
  confirm_recharge_number: "Confirmation du numéro de recharge",
  operations: {
    deposit: "Effectuer un dépôt",
    withdraw: "Effectuer un retrait",
  },
  placeholders: {
    give_confirmation_code: "Code reçu par SMS",
  },
  labels: {
    did_you_want_to_save: "Voulez-vous vraiment enregistrer ?",
    confirmation_code_precision: "Le code a été envoyé au ",
    give_confirmation_code: "Entrez le code de confirmation reçu par SMS.",
  },
  messages: {
    message_price: "Le SMS de modification est facturé à",
    code_sent:
      "un code sera envoyé sur votre téléphone pour la confirmation du numéro.",
  },
};
