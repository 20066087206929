import axios from "axios";

export default {
  blockchainMonitorCallback({ reference, key }, params) {
    let url =
      "https://api.be-wallet.net/blockchain-monitor/invoice-callback-notify";
    if (reference && key) {
      url += `?reference=${reference}&key=${key}`;
    }
    return axios.post(url, params);
  },
};
