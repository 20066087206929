export default {
  account_balance: "Solde de compte",
  pubcard_desc:
    "Acheter et recharger vos cartes VISA UBA cote d'ivoire, Burkina " +
    "et un clic et sans frais supplementaires",
  is_ben_country_desc:
    "Chers clients les dépôts et retraits Moov Money sont indisponibles. " +
    "L'équipe technique travail pour résoudre rapidement le problème !",
  is_sen_country_desc:
    "Chers clients les dépôts et retraits Orange Money sont indisponibles. " +
    "L'équipe technique travail pour résoudre rapidement le problème !",
  is_complete_profile_desc: "Votre profil n'est pas encore complété.",
  have_invalid_phone_number_desc: "Votre numéro de téléphone n'est pas valide.",
  phone_not_confirm_desc: "Votre numéro de téléphone n'est encore confirmé.",
  complete_now: "Compléter maintenant",
  update_now: "Mettre à jour",
  confirm_now: "Confirmer maintenant",
};
