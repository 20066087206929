export default {
  subtotal: "Sous total",
  unit_price: "Prix unitaire",
  total_price: "Prix total",
  travel: "Voyage",
  perform: "Effectuer",
  visa_card: "Carte VISA",
  please_note: "Veuillez noter",
  you_ll_receive_code_by_sms:
    "Vous allez recevoir votre code voyage par SMS au numéro",
  for: "pour",
  to: "à",
  passenger_s: "passager(s)",
  ticket: "Billet",
  departure: "Départ",
  name_and_surname: "Nom & Prénom",
  service: "Service",
  available_s: "disponibles",
  back_to_applications: "Retour aux applications",
  important: "Important !",
  yes_continue: "Oui Continuer",
  please_: "Veuillez",
  return: "Retourner",
  choose_: "choisir",
  input: "saisir",
  insert_the: "Entrez le",
  choose_an_operator: "Choisir l'opérateur",
  choose_number: "Choisir le numéro",
  correction: "Correction",
  new: "Nouveau",
  me: "Moi",
  sent: "Envoyé",
  submit: "Soumettre",
  received: "Reçu",
  received_from: "Reçu de ",
  sent_to: "Envoyé à ",
  customer_name: "Nom du client",
  choose_date: "Choisir la date",
  birth_date: "Date de naissance",
  insert_birth_date: "Veuillez entrez la date de naissance",
  insert_name: "Veuillez entrez le nom",
  insert_surname: "Veuillez entrez le prénom",
  creation_date: "Date de création",
  date: "Date",
  fees: "Frais",
  amount: "Montant",
  pending: "En attente",
  balance: "Solde",
  ref: "Ref",
  reference: "Référence",
  actions: "Actions",
  identifier: "Identifiant",
  amount_status: "Statut du montant",
  create_a_ticket: "Créer un ticket",
  balance_after: "Solde après opération",
  description: "Description",
  operator: "Opérateur",
  operation: "Opération",
  notifications: "Notifications",
  contract_number: "Numéro de contrat",
  counter_number: "Numéro de compteur",
  gender: "Sexe",
  name_placeholder: "Entrez le nom",
  surname_placeholder: "Entrez le prénom",
  surname: "Prénom",
  name: "Nom",
  not_defined: "Non défini",
  add_with_plus: "+ Ajouter",
  add: "Ajouter",
  choose: "--Choisir--",
  choose_o: "Choisir",
  delete_account: "Supprimer le numéro",
  add_contact_with_plus: "+ Ajouter un contact",
  add_contact: "Ajouter un contact",
  number: "Numéro",
  phone_number: "Numéro de téléphone",
  withdraw_amount: "Montant du retrait",
  insert_amount: "Entrez le montant",
  balance_after_validation: "Solde hors taxe après validation de l'opération",
  card: "Carte",
  card_number: "Numéro de carte",
  number_with_space: " Numéro ",
  maximum: "Maximum",
  loading: "En cours",
  finished: "Terminé",
  waiting: "Soumis",
  label: "Label",
  title: "Titre",
  find: "Chercher",
  search: "Rechercher",
  status: "Statut",
  created_at: "Crée le",
  modified_at: "Modifié le",
  modified_at_f: "Modifiée le",
  updated_at: "Mis à jour le",
  updated_at_f: "Mise à jour le",
  attention: "Attention",
  back: "Retour",
  close: "Fermer",
  cancel: "Annuler",
  confirm: "Confirmer",
  start: "Commencer",
  save: "Enregistrer",
  send: "Envoyer",
  update: "Modifier",
  sms: "SMS",
  insert_password: "Veuillez saisir votre mot de passe",
  password_required_4_digits:
    "Le mot de passe doit avoir au moins 4 caractères",
  confirm_operation: "Confirmer vous l’operation",
  answer: "Répondre",
  answer_with_dots: "Répondre...",
  for_this_operation: "pour cette opération",
  receiver: "Bénéficiaire",
  not_specified: "Non spécifié",
  wave_alert:
    "Attention: Vous devez écrire le nom complet exacte avec lequel vous avez créé votre compte Wave.",
  wave_confirm:
    "Vérifiez bien que le nom {name} est le nom EXACT de votre compte wave, en cas d'erreur le compte ne sera pas crédité." +
    " Merci d'envoyer EXACTEMENT le montant {amount} CFA en payant les frais",
};
