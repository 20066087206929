import axios from "axios";

export default {
  getDepositList(page, field) {
    return axios.post("/depots/list?page=" + page, field);
  },
  searchFromDepositList(page, field) {
    return axios.post("/depots/list/search?page=" + page, field);
  },
  getDeposit(id) {
    return axios.get(`/depots/${id}`);
  },
  initiateDeposit(payload) {
    return axios.post("/depots/initiate", payload);
  },
  getApiTaxDetail(payload) {
    return axios.post("/depots/api-tax-amount", payload);
  },
  verifyDeposit(id) {
    return axios.get(`/depots/${id}/init/get-state`);
  },
};
