<template>
  <section class="header-chat">
    <div class="h-list-header">
      <!-- <div
        class="font-weight-bold text-truncate"
      >
        <span v-if="ticket">
          <b
            >{{ ticket.reference }} - <em
              :class="ticket.is_active ? 'text-success' : 'text-secondary'"
            >
              {{ ticket.is_active ? " Actif " : " Fermé " }}</em
            ></b
          ></span
        >
        <BaseLoader :isSmall="true" v-else />
      </div> -->
      <header-with-divider
        v-if="ticket"
        :text="chatConversationTitle"
        :color="'blue'"
      />
      <BaseLoader :isSmall="true" v-else />
      <div>
        <a href="#" @click.prevent="closeMessage"><i class="icon-close"></i></a>
      </div>
    </div>
    <div class="h-list-body">
      <div
        class="ticket-details-content mb-2"
        v-if="ticket"
        :class="{ opened: ticketDetailInfo }"
      >
        <div class="ticket-header" @click="openTicketDetailsInfo">
          <p class="ticket-title">
            {{ $t("assistance.ticket_title") }}
          </p>
          <p class="ticket-description">
            {{ $t("assistance.ticket_description") }}
          </p>
        </div>
        <div class="ticket-body">
          <ticket-detail-header-infos :ticket="ticket" />
          <div class="footer">
            <div
              class="close-btn be-btn secondary"
              @click.prevent="closeTicket"
            >
              <BaseLoader :is-small="true" class="m-r-10" v-if="loadingClose" />
              <i class="icon-success"></i>
              <span class="text-green">
                {{ $t("assistance.close") }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="main-chat-cont scroll-div" id="ticket_chat_body">
        <div class="main-friend-chat">
          <template v-if="ticket">
            <template v-if="ticket.messages.length > 0">
              <ticket-message-item
                v-for="message in ticket.messages"
                :key="message.id"
                :message="message"
              />
            </template>
            <BaseEmptyList :model="listModel" v-else />
          </template>
        </div>
      </div>
    </div>
    <div class="h-list-footer">
      <div class="input-group">
        <input
          type="file"
          class="chat-attach"
          style="display: none;"
          @change.prevent="onFileChange"
        />
        <a
          href="#!"
          class="input-group-prepend be-btn secondary grey btn-attach"
          :class="{ disabled: loadingClose }"
          @click.prevent="openBtnChooseFile"
        >
          <i class="icon-attachment" v-if="!loadingUpload"></i>
          <BaseLoader
            :isSmall="true"
            v-if="loadingUpload"
            text-color="text-white"
          />
        </a>
        <input
          type="text"
          v-model="message"
          class="form-control h-send-chat"
          :disabled="loadingClose"
          :placeholder="$t('common.answer_with_dots')"
          @input="$v.message.$touch()"
          @keypress.enter="handleSendMessage"
        />
        <button
          type="button"
          @click="handleSendMessage"
          class="input-group-append btn-send be-btn secondary blue"
        >
          <i class="icon-send" v-if="!loading"></i>
          <BaseLoader :isSmall="true" v-if="loading" text-color="text-white" />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TicketDetailHeaderInfos from "./TicketDetailHeaderInfos";
import TicketMessageItem from "./TicketMessageItem";
import { required, minLength } from "vuelidate/lib/validators";
import { emptyListModel } from "../../helpers/constants";
import HeaderWithDivider from "../common/HeaderWithDivider";

export default {
  name: "TicketDetails",
  components: { TicketMessageItem, TicketDetailHeaderInfos, HeaderWithDivider },
  computed: {
    ...mapState("assistance", ["ticket"]),
    ...mapGetters("auth", ["userId"]),
    listModel() {
      return emptyListModel.message;
    },
    chatConversationTitle() {
      let x;
      this.ticket.is_active ? (x = " Actif ") : (x = " Fermé ");
      return this.ticket.reference + " - " + x;
    },
  },

  data() {
    return {
      loading: false,
      loadingUpload: false,
      loadingClose: false,
      message: null,
      error: null,
      timeout: null,
      ticketDetailInfo: false,
    };
  },

  watch: {
    "ticket.messages": {
      deep: true,
      handler() {
        const container = this.$el.querySelector(".main-chat-cont");
        setTimeout(() => {
          container.scrollTo({
            top: container.scrollHeight + 100,
          });
        }, 100);
      },
    },
  },

  mounted() {
    const container = this.$el.querySelector(".main-friend-chat");
    container.scrollTo(0, container.offsetTop);
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    closeTicket() {
      this.loadingClose = true;
      this.$store.dispatch("assistance/closeTicket").then(() => {
        this.closeMessage();
        this.loadingClose = false;
      });
    },

    onFileChange(event) {
      if (event.target.files && event.target.files.length > 0) {
        this.loadingUpload = true;
        let formData = new FormData();
        const file = event.target.files[0];
        formData.append("document", file);
        this.$store
          .dispatch("assistance/uploadImage", formData)
          .then(data => {
            this.message = `<a href="${data.chemin}" target="_blank"><img src="${data.chemin}" class="img-fluid" width="80%"/></a>`;
            this.loadingUpload = false;
            this.handleSendMessage();
          })
          .catch(err => {
            if (err.message) this.error = err.message;
            this.loadingUpload = false;
          });
      }
    },

    handleSendMessage() {
      if (!this.$v.invalid) {
        this.loading = true;
        this.$store
          .dispatch("assistance/sendNewMessage", { message: this.message })
          .then(() => {
            this.loading = false;
            this.message = null;
          })
          .catch(err => {
            if (err.message) this.error = err.message;
            this.loading = false;
            this.message = null;
          });
      }
    },

    openBtnChooseFile() {
      window.$(".chat-attach").trigger("click");
    },

    closeMessage() {
      window.$(".header-chat").removeClass("open");
      window.$(".header-user-list").removeClass("msg-open");
    },
    openTicketDetailsInfo() {
      this.ticketDetailInfo = !this.ticketDetailInfo;
    },
  },

  validations: {
    message: { required, minLength: minLength(10) },
  },
};
</script>

<style scoped></style>
