<template>
  <div class="landing-page">
    <!-- Header -->
    <div class="navbar-container">
      <div class="container">
        <div class="row">
          <button
            aria-label="Toggle navigation"
            class="navbar-toggler landing-toggle f-24 ml-3"
            type="button"
            @click="onNavbarCollapseClick"
          >
            <span class="icon-menu show-navbar">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </span>
            <span class="icon-menu-close-mobile hide-navbar">
              <span class="path1"></span>
              <span class="path2"></span>
            </span>
          </button>
          <div class="col-xl-5 col-md-3 col-sm-12 navbar-container-left">
            <a class="navbar-logo" href="#iq-home">
              <img
                alt="#"
                class="img-fluid"
                src="../assets/images/logo/logo_landing.svg"
              />
            </a>
          </div>
          <scrollactive
            :offset="80"
            active-class="active"
            class="col-xl-7 col-md-9 navbar-container-right navbar-collapse-custom"
          >
            <a
              class="scrollactive-item"
              href="#iq-home"
              @click="onNavbarLinkClick"
            >
              {{ $t("landing.menu.home") }}
            </a>
            <a class="scrollactive-item" href="#iq-services">
              {{ $t("landing.menu.services") }}
            </a>
            <a class="scrollactive-item" href="#iq-enterprise">
              {{ $t("landing.menu.offers") }}
            </a>
            <a class="scrollactive-item" href="#iq-contact">
              {{ $t("landing.menu.contact") }}
            </a>
            <a
              :href="loggedIn ? '/account/withdraw' : '/register'"
              class="nav-button"
              >{{
                loggedIn
                  ? $t("landing.menu.withdraw")
                  : $t("landing.menu.start")
              }}</a
            >
          </scrollactive>
        </div>
      </div>
    </div>
    <!-- Header End -->
    <section id="iq-home" class="landing-container-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-sm-12 landing-container-1-left">
            <h1>
              {{ $t("landing.a_door") }} <br />
              <b>{{ $t("landing.cash") }}</b
              ><br />
              {{ $t("landing.very_helpful") }}
            </h1>
            <div class="mt-3">
              <a
                :href="loggedIn ? '/account/dashboard' : '/register'"
                class="landingButton blue-button mr-3"
                >{{
                  loggedIn
                    ? $t("landing.menu.dashboard")
                    : $t("landing.menu.create_account")
                }}</a
              >
              <a
                v-if="loggedIn"
                class="landingButton white-button"
                href="#"
                @click.prevent="landingLogout"
                >{{ $t("landing.menu.sign_out") }}</a
              >
              <a
                v-if="!loggedIn"
                class="landingButton white-button"
                href="/login"
              >
                {{ $t("landing.menu.sign_in") }}
              </a>
            </div>
          </div>
          <div class="col-sm-7 landing-container-1-right">
            <img
              id="section1_img"
              alt="#"
              class="img-fluid"
              src="../assets/landing/images/section1.png"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- services -->
    <section id="iq-services" class="landing-container-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 title-box-left">
            <h1>
              {{ $t("landing.welcome_in") }} <br />
              <b>{{ $t("landing.world_of_service") }}</b>
            </h1>
          </div>
          <div class="col-lg-6 col-sm-12 title-box-right">
            <p>{{ $t("landing.landing_desc") }}</p>
          </div>
        </div>
      </div>
      <div class="card-container">
        <div class="landing-card">
          <img alt="#" src="../assets/landing/images/icons/bolt.svg" />
          <h3>{{ $t("landing.instantaneous") }}</h3>
          <p>{{ $t("landing.instantaneous_desc") }}</p>
        </div>
        <div class="landing-card landing-card-2">
          <img alt="#" src="../assets/landing/images/icons/cube.svg" />
          <h3>{{ $t("landing.transparency") }}</h3>
          <p>{{ $t("landing.transparency_desc") }}</p>
        </div>
        <div class="landing-card landing-card-3">
          <img alt="#" src="../assets/landing/images/icons/cloud-check.svg" />
          <h3>{{ $t("landing.buy_online") }}</h3>
          <p>{{ $t("landing.buy_online_desc") }}</p>
        </div>
        <div class="landing-card landing-card-4">
          <img alt="#" src="../assets/landing/images/icons/heart.svg" />
          <h3>{{ $t("landing.economic") }}</h3>
          <p>{{ $t("landing.economic_desc") }}</p>
        </div>
      </div>
    </section>

    <section class="landing-container-3 landing-container-3-web container">
      <div class="row">
        <div class="col-lg-12 col-xl-6 landing-container-3-right">
          <img alt="" src="../assets/landing/images/devices.png" />
        </div>
        <div class="col-lg-12 col-xl-6 landing-container-3-left">
          <div>
            <h3>
              {{ $t("landing.cash_get_intro") }} <br />
              <b>{{ $t("landing.cash_get") }}</b
              ><br />
            </h3>
            <p>{{ $t("landing.cash_desc") }}</p>
            <a
              :href="loggedIn ? '/account/withdraw' : '/register'"
              class="landingButton black-button"
              >{{
                loggedIn
                  ? $t("landing.menu.withdraw")
                  : $t("landing.menu.start")
              }}</a
            >
          </div>
        </div>
      </div>
    </section>

    <section class="landing-container-3 landing-container-3-mobile container">
      <div>
        <img
          alt=""
          class="device_mobile"
          src="../assets/landing/images/devices_mobile.png"
        />
        <div class="landing-container-3-left">
          <h3>
            {{ $t("landing.cash_get_intro") }} <br />
            <b>{{ $t("landing.cash_get") }}</b
            ><br />
          </h3>
          <p>{{ $t("landing.cash_desc") }}</p>

          <a
            :href="loggedIn ? '/account/withdraw' : '/register'"
            class="landingButton black-button"
            >{{
              loggedIn ? $t("landing.menu.withdraw") : $t("landing.menu.start")
            }}</a
          >
        </div>
      </div>
    </section>

    <section id="iq-enterprise" class="landing-container-4 container">
      <div class="row mb-4">
        <div class="col-lg-6 col-sm-12 title-box-left">
          <h1>
            {{ $t("landing.a_solution") }} <br />
            <b>{{ $t("landing.any_needs") }}</b>
          </h1>
        </div>
        <div class="col-lg-6 col-sm-12 title-box-right">
          <p>
            {{ $t("landing.a_solution_desc") }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <div class="offres-card">
            <div>
              <img alt="#" src="../assets/landing/images/icons/qr-code.svg" />
              <h3>{{ $t("landing.e_merchant") }}</h3>
            </div>
            <p>
              {{ $t("landing.e_merchant_desc") }}
            </p>
            <a
              :href="loggedIn ? '/account/withdraw' : '/register'"
              class="landingButton black-button"
              >{{
                loggedIn
                  ? $t("landing.menu.withdraw")
                  : $t("landing.menu.start")
              }}</a
            >
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="offres-card offres-card-2">
            <div>
              <img alt="#" src="../assets/landing/images/icons/user-view.svg" />
              <h3>{{ $t("landing.service_providers") }}</h3>
            </div>
            <p>
              {{ $t("landing.become_a_partner_desc") }}
            </p>
            <a :href="'/register'" class="landingButton black-button">
              {{ $t("landing.menu.become_a_partner") }}
            </a>
          </div>
        </div>
      </div>
      <div class="statistic-box">
        <div class="statistic-item">
          <p class="statistic-number">10+</p>
          <p class="statistic-desc">
            {{ $t("landing.africa_countries") }}
          </p>
        </div>
        <div class="statistic-item">
          <p class="statistic-number">900K+</p>
          <p class="statistic-desc">
            {{ $t("landing.satisfied_customers") }}
          </p>
        </div>
        <div class="statistic-item">
          <p class="statistic-number">30+</p>
          <p class="statistic-desc">
            {{ $t("landing.available_services") }}
          </p>
        </div>
        <div class="statistic-item">
          <p class="statistic-number">10M+</p>
          <p class="statistic-desc">{{ $t("landing.transactions") }}</p>
        </div>
      </div>
    </section>

    <section id="iq-contact" class="landing-container-5 container">
      <div class="row">
        <div class="col-lg-6 col-sm-12 title-box-left">
          <h1>
            {{ $t("landing.payment_methods_desc") }}<br />
            <b>{{ $t("landing.payment_methods") }}</b>
          </h1>
        </div>
        <div class="col-lg-6 col-sm-12 title-box-right">
          <p>{{ $t("landing.payment_description") }}</p>
        </div>
      </div>
      <!-- v-bind:src="`../assets/landing/partners/${n}.png`" -->
      <div class="row partner-section">
        <div class="partner-section-1">
          <div v-for="partner in partners[0]" :key="partner" class="partner">
            <img
              :src="require(`../assets/landing/partners/${partner}.png`)"
              alt="#"
              class="img-fluid partner-img"
            />
          </div>
        </div>
        <div class="partner-section-2">
          <div v-for="partner in partners[1]" :key="partner" class="partner">
            <img
              :src="require(`../assets/landing/partners/${partner}.png`)"
              alt="#"
              class="img-fluid partner-img"
            />
          </div>
        </div>
        <div class="partner-section-2">
          <div v-for="partner in partners[2]" :key="partner" class="partner">
            <img
              :src="require(`../assets/landing/partners/${partner}.png`)"
              alt="#"
              class="img-fluid partner-img"
            />
          </div>
        </div>
        <div class="partner-section-3">
          <div v-for="partner in partners[3]" :key="partner" class="partner">
            <img
              :src="require(`../assets/landing/partners/${partner}.png`)"
              alt="#"
              class="img-fluid partner-img"
            />
          </div>
        </div>
      </div>
      <div class="row partner-section-mobile">
        <div>
          <div
            v-for="partner in [...partners[0], ...partners[1], ...partners[2]]"
            :key="partner"
            class="partner"
          >
            <img
              :src="require(`../assets/landing/partners/${partner}.png`)"
              alt="#"
              class="img-fluid partner-img"
            />
          </div>
        </div>
      </div>
    </section>

    <footer class="container">
      <div class="landing-footer">
        <div class="footer-logo-container">
          <a class="footer-logo" href="#iq-home">
            <img
              alt="#"
              class="img-fluid"
              src="../assets/images/logo/logo_landing.svg"
            />
          </a>
          <p class="footer-address">{{ $t("landing.localisation") }}</p>
          <p class="footer-email">{{ $t("landing.menu.contact_email") }}</p>
          <p>{{ $t("landing.society_description") }}</p>
          <span>{{ $t("landing.user_conditions") }}</span>
        </div>
        <div class="footer-address-container">
          <h3 class="text-uppercase">{{ $t("landing.menu.address") }}</h3>
          <p class="footer-address">{{ $t("landing.localisation") }}</p>
          <p class="footer-email">{{ $t("landing.menu.contact_email") }}</p>
        </div>
        <div class="footer-sitemap-container">
          <h3>{{ $t("landing.menu.sitemap") }}</h3>
          <scrollactive
            :offset="80"
            active-class="active"
            class="footer-navbar"
          >
            <a
              class="scrollactive-item"
              href="#iq-home"
              @click="onNavbarLinkClick"
              >{{ $t("landing.menu.home") }}</a
            >
            <a
              class="scrollactive-item"
              href="#iq-services"
              @click="onNavbarLinkClick"
              >{{ $t("landing.menu.services") }}</a
            >
            <a class="scrollactive-item" href="#iq-enterprise">
              {{ $t("landing.menu.offers") }}
            </a>
            <a class="scrollactive-item" href="#iq-contact">
              {{ $t("landing.menu.contact") }}
            </a>
          </scrollactive>
        </div>
      </div>
      <Footer class="footer-copyrights" />
    </footer>
    <div id="back-to-top">
      <a class="top" href="#top" @click="onTooltipBtn">
        <i class="feather icon-arrow-up"></i>
      </a>
    </div>
    <!-- back-to-top End -->
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Footer from "../components/layouts/Footer";
// import Header from "../components/layouts/Header.vue";

export default {
  components: { Footer },
  name: "Landing",
  data() {
    return {
      date: new Date(),
      partners: [
        [
          "bitcoin",
          "camtel",
          "yoomee",
          "sodeci",
          "uba",
          "orange",
          "cieciv",
          "nexttel",
        ],
        ["mtn", "moov", "visa", "mastercard", "ligdicash", "camwater"],
        ["cellcom", "cinetpay", "emoney", "freemoney", "wave"],
        ["mobicash", "canal", "eneo", "paydunya"],
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["loggedIn"]),
  },
  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`),
    };
  },
  mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      (async () => {
        await Vue.loadScript(
          `${window.location.origin}/assets/landing/js/jquery-3.3.1.min.js`
        );
        await Vue.loadScript(
          `${window.location.origin}/assets/landing/js/popper.min.js`
        );
        await Vue.loadScript(
          `${window.location.origin}/assets/landing/js/main.js`
        );
        await Vue.loadScript(
          `${window.location.origin}/assets/landing/js/custom.js`
        );
      })();
    }, 1000);
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    onNavbarLinkClick() {
      window.$(".navbar-collapse").removeClass("show");
    },
    onNavbarCollapseClick() {
      if (window.$(".show-navbar").is(":visible")) {
        window.$(".navbar-container").addClass("blur-navbar");
      } else {
        window.$(".navbar-container").removeClass("blur-navbar");
      }
      window.$(".navbar-collapse-custom").toggleClass("flexbox");
      window.$("body").toggleClass("hidden-overflow");
      window.$(".show-navbar").toggle();
      window.$(".hide-navbar").toggle();
    },
    landingLogout() {
      this.logout(true).then(() => {
        location.reload();
      });
    },
    onTooltipBtn() {
      window.$("body,html").animate(
        {
          scrollTop: 0,
        },
        800
      );
    },
  },
};
</script>
