import { mapGetters } from 'vuex';

export const assistanceMixin = {
  data() {
    return {
      loadingHelp: false,
      field: {
        paginate: 5,
        is_archive: false,
        is_active: true,
        users_id: null,
        order_by: 'updated_at',
        direction: 'DESC',
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['country_id', 'userId']),
  },

  methods: {},
};
