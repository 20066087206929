<template>
  <div :class="baseButtonClass" class="base-button">
    <button
      v-on="$listeners"
      v-bind="$attrs"
      class="wButton"
      :class="buttonClass"
    >
      <span
        v-if="showLoading && loading"
        class="loading-spinner spinner-grow spinner-grow-sm"
        role="status"
      ></span>
      <slot />
    </button>
  </div>
</template>

<script>
export default {
  name: "BaseButton",
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    baseButtonClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.wButton {
  background: #0071ff 0 0 no-repeat padding-box;
  border-radius: 21px;
  height: 42px;
  border: none;
  margin-bottom: 30px;
}
</style>
