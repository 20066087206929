<template>
  <div class="form-group">
    <div class="input-group">
      <img src="../../assets/images/icons/user.png" class="input_icon" />
      <select
        v-on="listeners"
        v-bind="$attrs"
        :value="value"
        class="wSelect"
        :class="{ 'is-invalid': isInvalid }"
      >
        <option :value="null" disabled>{{ placeholder }}</option>
        <option
          v-for="option in options"
          :key="idValue ? option[valueField] : option"
          :value="idValue ? option[valueField] : option"
          :selected="idValue ? option[valueField] === value : option === value"
          >{{ idValue ? option[showField] : option }}</option
        >
      </select>
    </div>
  </div>
</template>

<script>
import { formFieldMixin } from "../../mixins/formFieldMixin";

export default {
  name: "BaseSelect",
  mixins: [formFieldMixin],
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    idValue: {
      type: Boolean,
      default: false,
    },
    showField: {
      type: String,
      default: "",
    },
    valueField: {
      type: String,
      default: "id",
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
  },
};
</script>

<style scoped>
.wSelect {
  background: #f5f6f8 no-repeat padding-box;
  border-radius: 25px;
  height: 50px;
  padding: 12px 24px 12px 54px;
  font-size: 14px;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../assets/images/icons/caret-down.png);
  background-size: 16px;
  background-position: 95% 50%;
}
.input_icon {
  position: absolute;
  margin-top: 16px;
  left: 25px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
</style>
