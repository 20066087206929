import Vue from "vue";
import Vuex from "vuex";
import CountryService from "../services/CountryService";
import auth from "./modules/auth";
import notification from "./modules/notification";
import withdraw from "./modules/withdraw";
import transfer from "./modules/transfer";
import deposit from "./modules/deposit";
import shopping from "./modules/shopping";
import assistance from "./modules/assistance";
import payment from "./modules/payment";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
  state: {
    countries: [],
    echo_connect: true,
    routerLoading: false,
    sidebarState: true,
    ticketsState: false,
  },
  mutations: {
    SET_SIDEBAR_STATE(state, sidebarState) {
      state.sidebarState = sidebarState;
    },
    SET_TICKETS_STATE(state, ticketsState) {
      state.ticketsState = ticketsState;
    },
    SET_ROUTER_LOADING(state, routerLoading) {
      state.routerLoading = routerLoading;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    CHANGE_ECHO_CONNECT(state, status) {
      state.echo_connect = status;
    },
  },
  actions: {
    checkEndMaintenanceMode() {
      return CountryService.checkEndOfMaintenanceMode().then(({ data }) => {
        return data;
      });
    },
    setSidebarState({ commit }, sidebarState) {
      commit("SET_SIDEBAR_STATE", sidebarState);
    },
    setTicketsState({ commit }, ticketsState) {
      commit("SET_TICKETS_STATE", ticketsState);
    },
    getCountries({ commit, state }) {
      if (state.countries.length > 0) return state.countries;
      else {
        return CountryService.getCountries().then(response => {
          commit("SET_COUNTRIES", response.data);
        });
      }
    },
    changeEchoConnexionState({ commit }, status) {
      commit("CHANGE_ECHO_CONNECT", status);
    },
    startRouterLoading({ commit }) {
      commit("SET_ROUTER_LOADING", true);
    },
    stopRouterLoading({ commit }) {
      commit("SET_ROUTER_LOADING", false);
    },
  },
  modules: {
    auth,
    notification,
    withdraw,
    transfer,
    deposit,
    shopping,
    assistance,
    payment,
  },
  strict: debug,
});
