export default {
  assistance_and_reclamations: "Assistance & Réclamations",
  sms_proof: "Capture du SMS de l'opération",
  view_picture: "Consulter l'image",
  ticket_title: "Titre du ticket",
  ticket_description: "Description du ticket",
  assistance_center: "Centre d'assistance",
  assistance_desc: "Description de l'assistance",
  assistance_desc_placeholder: "Entrez une description de l'assistance",
  please_wait: "Merci de patienter et toutes nos excuses pour le désagrément.",
  treatment_time: "Le temps de traitement est de 24 heures.",
  complaint_saved: "Votre plainte a été enregistrée avec succès.",
  complaint_saved_successfully:
    "Votre plainte a été enregistrée avec succès. Le temps de traitement" +
    " est de 24 heures. Merci de patienter et toutes nos excuses" +
    " pour le désagrément.",
  close: "Clôturer",
  reclamation: "Réclamation",
  reclamation_type: "Type de réclamation",
  reclamation_type_placeholder: "Choisir le type",
  new_reclamation: "Nouvelle réclamation",
  choose_operator: "Choisir l'opérateur",
  choose_service: "Choisir le service concerné",
  active_service: "Service concerné",
  active_operator: "Opérateur concerné",
  active_operation: "Opération concernée",
  active_number: "Numéro concerné",
  cant_create_ticket: "-- Impossible de créer un ticket --",
  insert_ticket_description: "Veuillez compléter la description du ticket.",
  ticket_invalid_ref_length:
    "Veuillez compléter la référence Moov Money à 13 caractères.",
  ticket_field_required: "Veuillez compléter tous les champs.",
  ticket_invalid_hash_length:
    "Veuillez compléter le hash de la transaction à 64 caractères.",
  select_relevant_operation: "Veuillez sélectionner l'opération concernée !",
  select_active_operation: "-- Sélectionner l'opération concernée --",
  select_active_number: "-- Sélectionner le numéro concerné --",
  active_operation_placeholder: "Choisir l'opération concernée",
  active_number_placeholder: "Choisir le numéro concerné",
  active_payment_placeholder: "Sélectionner le paiement concerné",
  select_ticket_type: "Veuillez selectionner le type de ticket",
  cant_create_ticket_from_loading_treatment:
    "Vous ne pouvez initier un ticket sur un retrait en cours de traitement. Veuillez patienter la fin du traitement s'il vous plaît.",
};
