<template>
  <div id="app">
    <transition name="slide-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<style>
/* @import "assets/plugins/bootstrap/css/bootstrap.min.css"; */
@import 'assets/fonts/fontawesome/css/fontawesome-all.min.css';
@import 'assets/plugins/animation/css/animate.min.css';
@import 'assets/plugins/prism/css/prism.min.css';
@import 'assets/fonts/flag/css/flag-icon.min.css';
@import 'assets/plugins/modal-window-effects/css/md-modal.css';
@import 'assets/scss/style.css';
@import 'assets/plugins/data-tables/css/datatables.min.css';
@import 'assets/plugins/select2/css/select2.min.css';

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>

<script>
export default {
  created() {
    this.$echo.connector.socket.on('reconnect_error', () => {
      this.$store.dispatch('changeEchoConnexionState', false);
      this.$echo.disconnect();
    });
  },
};
</script>
