import WithdrawService from "../../services/WithdrawService";
import OperatorService from "../../services/OperatorService";

const state = {
  metaWithdraw: null,
  operators: [],
  contacts: [],
};

const getters = {
  withdraws: state => (state.metaWithdraw ? state.metaWithdraw.data : []),
  current_page: state =>
    state.metaWithdraw ? state.metaWithdraw.current_page : 1,
  last_page: state => (state.metaWithdraw ? state.metaWithdraw.last_page : 1),
  isWithdrawAvailable: state =>
    state.operators.find(op => op.is_retrait) !== undefined,
  getOperatorById: state => id => {
    return state.operators.find(op => op.id === id);
  },
  getContactById: state => id => {
    return state.contacts.find(c => c.id === id);
  },
};

const actions = {
  getWithdrawList({ commit, state }, { page, field, cancel }) {
    if (
      state.metaWithdraw &&
      page === state.metaWithdraw.current_page &&
      !field.search &&
      !cancel
    )
      return state.metaWithdraw;
    else {
      if (field.search)
        return WithdrawService.searchFromWithdrawList(page, field).then(
          response => {
            commit("SET_META_WITHDRAW", response.data);
          }
        );
      else
        return WithdrawService.getWithdrawList(page, field).then(response => {
          commit("SET_META_WITHDRAW", response.data);
        });
    }
  },
  getWithdraw({ getters, dispatch }, id) {
    const withdraw = getters.withdraws.find(w => w.id === id);
    if (withdraw !== undefined) return withdraw;
    else
      return WithdrawService.getWithdraw(id)
        .then(({ data }) => {
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  confirmWithdraw({ commit, dispatch }, { information, amount, password }) {
    return WithdrawService.confirmWithdraw({
      informations_id: information.id,
      amount,
      password: password,
      operators_id: information.operator.id,
    })
      .then(({ data }) => {
        commit("ADD_WITHDRAW", data.withdraw);
        const notification = {
          type: "success",
          message: data.message,
          is_toast: false,
        };
        dispatch("notification/add", notification, { root: true });
        dispatch("auth/getCurrentUser", null, { root: true });
        return data;
      })
      .catch(err => {
        console.log(err);
        if (err.response.data.message) {
          let notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        if (err.response.data.refresh)
          dispatch("auth/getCurrentUser", null, { root: true });
        return Promise.reject(err.response.data);
      });
  },

  getWithdrawOperators({ commit, state }) {
    if (state.operators.length > 0) return state.operators;
    else
      return OperatorService.getWithdrawOperators().then(({ data }) => {
        commit("SET_OPERATORS", data);
      });
  },

  getUserContacts({ commit, state }) {
    if (state.contacts.length > 0) return state.contacts;
    else
      return WithdrawService.getContacts().then(({ data }) => {
        commit("SET_CONTACTS", data);
      });
  },

  addContactInformation(
    { commit, dispatch },
    { nom, prenom, contacts_id, operator_id, phone }
  ) {
    if (contacts_id) {
      WithdrawService.editContact({ id: contacts_id, nom, prenom });
      return WithdrawService.addInformation({
        contacts_id,
        operators_id: operator_id,
        phone,
      }).then(({ data }) => {
        commit("ADD_INFORMATION", data.information);
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
        return data.information;
      });
    }
    return WithdrawService.addContact({
      operator_id,
      phone,
      nom,
      prenom,
    }).then(({ data }) => {
      commit("ADD_CONTACT_INFORMATION", data);
      return data;
    });
  },
  deleteInformation({ commit, dispatch }, information) {
    return WithdrawService.deleteInformation(information.id).then(
      ({ data }) => {
        commit("DELETE_INFORMATION", information);
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
      }
    );
  },
};

const mutations = {
  SET_META_WITHDRAW(state, metaWithdraw) {
    state.metaWithdraw = metaWithdraw;
  },
  ADD_WITHDRAW(state, withdraw) {
    if (state.metaWithdraw) state.metaWithdraw.data.unshift(withdraw);
  },

  SET_OPERATORS(state, operators) {
    state.operators = operators;
  },

  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
  },

  ADD_INFORMATION(state, information) {
    const contact = state.contacts.find(c => c.id === information.contacts_id);
    if (contact) contact.informations.push(information);
  },
  ADD_CONTACT_INFORMATION(state, information) {
    let contact = information.contact;
    contact.informations = [];
    contact.informations.push(information);
    state.contacts.push(contact);
  },
  DELETE_INFORMATION(state, information) {
    const contact = state.contacts.find(c => c.id === information.contacts_id);
    if (contact)
      contact.informations = contact.informations.filter(
        info => info.id !== information.id
      );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
