import { render, staticRenderFns } from "./BeMultiselect.vue?vue&type=template&id=158a75b3&scoped=true&"
import script from "./BeMultiselect.vue?vue&type=script&lang=js&"
export * from "./BeMultiselect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158a75b3",
  null
  
)

export default component.exports