import axios from "axios";

export default {
  getTickets(page, field) {
    return axios.post(`/tickets/list?page=${page}`, field);
  },
  getTicketByReference(reference) {
    return axios.get(`/tickets/${reference}`);
  },
  getTicketTypes() {
    return axios.get("/tickets/types/list");
  },
  uploadImage(formData) {
    return axios.post("/tickets/documents/upload", formData);
  },
  addNewTicket(payload) {
    return axios.post("/tickets", payload);
  },
  updateTicket(ticket) {
    return axios.put(`/tickets/${ticket.id}`, ticket);
  },
  addTicketMessage(payload) {
    return axios.post("/tickets/messages/store", payload);
  },
  addNewNumberTicket(payload) {
    return axios.post("/tickets/numbers/reporting", payload);
  },
};
