<template>
  <div class="media chat-messages">
    <label class="media-left photo-table" v-if="!isCurrentUser">
      <!-- <img
        class="media-object img-radius shadow-sm img-radius m-t-5"
        src="https://via.placeholder.com/100/3f4d67/FFFFFF?text=BWT"
        alt="BWT"
    /> -->
      BWT
    </label>

    <div :class="messageClass">
      <div>
        <p class="chat-cont" v-html="message.message" />
      </div>
      <p class="chat-time text-dark">
        {{ message.created_at | moment("H:m:s") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TicketMessageItem",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["userId"]),
    isCurrentUser() {
      return this.message.users_id
        ? this.message.users_id === this.userId
        : true;
    },
    messageClass() {
      return this.isCurrentUser
        ? "media-body chat-menu-reply"
        : "media-body chat-menu-content";
    },
  },
};
</script>

<style scoped></style>
