var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"base-input-wrapper"}},[(!!_vm.label)?_c('div',{staticClass:"input-label f-w-600",class:{ 'hljs-strong': _vm.bold, 'input-danger': _vm.errorMessage }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input-group position-relative",class:{ leftIconInactive: !!_vm.leftIconClass && !_vm.value }},[(_vm.leftIconClass)?_c('i',{class:[_vm.leftIconClass, 'input-icon']}):_vm._e(),(_vm.isPhoneInput)?_c('span',{staticClass:"country-code"},[_vm._v("+"+_vm._s(_vm.countryCode))]):_vm._e(),_c('input',_vm._g(_vm._b({class:{
        'pl-65': _vm.isPhoneInput,
        'is-invalid': _vm.isInvalid,
        wInput: true,
        disabled: _vm.disabled,
        'hljs-strong': !!_vm.bold,

        hasLeftIcon: !!_vm.leftIconClass,
      },attrs:{"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.updateValue}},'input',_vm.$attrs,false),_vm.listeners)),(_vm.rightText)?_c('span',{staticClass:"input-icon-text"},[_vm._v(_vm._s(_vm.rightText))]):_vm._e(),(_vm.rightIconClass)?_c('i',{class:[_vm.rightIconClass, 'rightIcon']}):_vm._e(),_vm._t("default")],2),(_vm.description && !_vm.errorMessage)?_c('div',{staticClass:"input-description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"input-error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }