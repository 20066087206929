<template>
  <div class="be-toaster" :class="notificationClass">
    <div class="close-btn" @click.prevent="remove(notification)">
      <span aria-hidden="true"><i class="icon-close"></i></span>
    </div>
    <div class="be-toaster-icon">
      <i :class="notificationIcon"></i>
    </div>
    <div class="toaster-body d-flex align-items-center">
      <div class="message">{{ notification.message }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "NotificationBar",
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  mounted() {
    // if (this.notification.is_toast) {
    //   switch (this.notification.type) {
    //     case "info":
    //     case "success":
    //       this.$toast.success(
    //         this.notification.message,
    //         this.notification.title,
    //         this.notificationSystem.options.success
    //       );
    //       break;
    //     case "danger":
    //     case "error":
    //       this.$toast.error(
    //         this.notification.message,
    //         this.notification.title,
    //         this.notificationSystem.options.error
    //       );
    //       break;
    //   }
    //   this.remove(this.notification);
    // } else
    this.timeout = setTimeout(
      () => this.remove(this.notification),
      this.notification.timeout ? this.notification.timeout : 5000
    );
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: mapActions("notification", ["remove"]),
  computed: {
    ...mapState("notification", ["notificationSystem"]),
    notificationClass() {
      return `be-toaster-${this.notification.type}`;
    },
    notificationIcon() {
      if (this.notification.type === "danger") {
        return `icon-warning-fill`;
      }
      return `icon-${this.notification.type}-fill`;
    },
  },
};
</script>

<style scoped></style>
