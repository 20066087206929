export default {
  localisation: "Abidjan, Angré 8ème tranche",
  menu: {
    contact_email: "infos@be-wallet.net",
    sitemap: "Sitemap",
    address: "Adresse",
    home: "Accueil",
    services: "Services",
    offers: "Offres d'entreprises",
    contact: "Contact",
    dashboard: "Tableau de bord",
    create_account: "Créer un compte",
    sign_out: "Déconnexion",
    sign_in: "Se connecter",
    become_a_partner: "Devenez partenaire",
    start: "Commencer",
    withdraw: "Effectuer un retrait",
  },
  a_door: "Un porte",
  cash: "monnaie",
  very_helpful: "vraiment utile",
  service_providers: "PRESTATAIRES DE SERVICES",
  become_a_partner_desc:
    "Que vous soyez patron d’une école, d’un restaurant, ou si vous fournissez un " +
    "service à l’abonnement. Même sans site internet, permettez à vos clients de " +
    "vous payer directement à partir de leur porte monnaie électronique BeWallet. " +
    "Devenez notre partenaire pour toucher plus de 100 000 utilisateurs actifs sur BeWallet.",
  africa_countries: "PAYS AFRICAINS",
  transactions: "TRANSACTIONS",
  satisfied_customers: "CLIENTS CONTENTS",
  available_services: "SERVICES DISPONIBLES",
  e_merchant: "E-MARCHAND",
  e_merchant_desc:
    "Encaissez et envoyez des paiements en ligne via Mobile Money dans plus de 20 pays en Afrique. " +
    "Une API avec intégration simple; vous allez adopter BeWallet pour sa simplicité ! Mais aussi pour " +
    "ses multiples options qui répondent particulièrement à vos besoins que vous soyez entrepreneur ou PME/ME/GE.",
  society_description:
    "Société à responsabilité limitée au Capital de 1.000.000 CFA.",
  payment_methods_desc: "Carrefour des ",
  payment_methods: "moyens de paiements",
  user_conditions: "Conditions générales d’utilisation",
  payment_description:
    "Que ce soit pour un retrait de fonds, dépôt d’argent ou un achat de services via votre compte BeWallet, " +
    "quelque soit votre pays, vous avez l’embarras du choix entre des dizaines de moyen de paiements. " +
    "Nous acceptons aussi bien le mobile money que les cartes bancaires.",
  any_needs: "soit vos besoins",
  a_solution: "Une solution quelque",
  a_solution_desc:
    "Découvrez une solution de paie globale, simple, efficace et fiable pour Toucher de nouveaux clients en Afrique.",
  welcome_in: "Bienvenue dans",
  world_of_service: "monde de services",
  landing_desc:
    "Votre porte-monnaie BeWallet vous donne accès à une dizaine de services en Afrique. " +
    "Abonnement Canal+, Achat du crédit téléphonique, Règlement de factures lumière/eau, " +
    "recharge de cartes VISA prépayées, achat en ligne, Ticket de bus ...",
  instantaneous: "INSTANTANÉ",
  instantaneous_desc:
    "Le paiement de facture/service via BeWallet est quasi instantané. Nous garantissons un temps " +
    "de traitement maximum de 5 minutes suivant les services.",
  transparency: "TRANSPARENCE",
  transparency_desc:
    "Votre argent est en lieu sûr! Nos ingénieurs veillent aux grains. Consultez dans le moindre détails " +
    "l’historique de vos opérations. Demandez une explication.",
  buy_online: "ACHETER EN LIGNE",
  buy_online_desc:
    "Payez en ligne en toute sécurité ! Les sites marchands qui utilisent BeWallet sont crédibles. " +
    "Nous les sélectionnons avec précision et vous garantissons la livraison de votre commande.",
  economic: "ÉCONOMIQUE",
  economic_desc:
    "Trouvez du temps pour vous et votre famille. Nous nous occupons des factures. Votre temps n’a pas de prix! " +
    "Pourtant, nous vou permettons de gagner un temps précieux.",
  cash_get_intro: "Accédez à votre",
  cash_get: "argent cash 24h/24",
  cash_desc:
    "En cas de besoin, vous avez la liberté de retirer l’ensemble des fonds disponibles " +
    "sur votre compte BeWallet sans conditions, ni frais.",
};
