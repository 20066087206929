import PaymentService from "../../services/PaymentService";

const state = {
  metaApplication: null,
  metaPayment: null,
};

const getters = {
  applications: state =>
    state.metaApplication ? state.metaApplication.data : [],
  app_current_page: state =>
    state.metaApplication ? state.metaApplication.current_page : 1,
  app_last_page: state =>
    state.metaApplication ? state.metaApplication.last_page : 1,
  payments: state => (state.metaPayment ? state.metaPayment.data : []),
  current_page: state =>
    state.metaPayment ? state.metaPayment.current_page : 1,
  last_page: state => (state.metaPayment ? state.metaPayment.last_page : 1),
};

const actions = {
  getApplications({ commit, state }, { page, field }) {
    if (state.metaApplication && page === state.metaApplication.current_page)
      return state.metaApplication;
    else {
      return PaymentService.getApplicationList(page, field).then(response => {
        commit("SET_META_APPLICATION", response.data);
      });
    }
  },
  changeApplicationLogo({ commit, dispatch }, { appId, formData }) {
    return PaymentService.uploadApplicationLogo(appId, formData)
      .then(({ data }) => {
        commit("CHANGE_APP_LOGO", { appId, data });
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  getPaymentList({ commit, state }, { page, field, cancel }) {
    if (
      state.metaPayment &&
      page === state.metaPayment.current_page &&
      field.applications_id === state.metaPayment.current_app &&
      !field.search &&
      !cancel
    )
      return state.metaPayment;
    else {
      if (field.search)
        return PaymentService.searchFromPaymentList(page, field).then(
          ({ data }) => {
            commit("SET_META_PAYMENT", { app: field.applications_id, data });
          }
        );
      else
        return PaymentService.getPaymentList(page, field).then(({ data }) => {
          commit("SET_META_PAYMENT", { app: field.applications_id, data });
        });
    }
  },
  getPayment({ getters, dispatch }, id) {
    const payment = getters.payments.find(p => p.id === id);
    if (payment !== undefined) return payment;
    else
      return PaymentService.getPayment(id)
        .then(({ data }) => {
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
};

const mutations = {
  SET_META_APPLICATION(state, metaApplication) {
    state.metaApplication = metaApplication;
  },
  ADD_APPLICATION(state, application) {
    if (state.metaApplication) state.metaApplication.data.push(application);
  },
  UPDATE_APPLICATION(state, { application }) {
    if (state.metaApplication) {
      let app = state.metaApplication.data.find(a => a.id === application.id);
      if (app !== undefined) app = application;
    }
  },
  CHANGE_APP_LOGO(state, { appId, data }) {
    if (state.metaApplication) {
      let application = state.metaApplication.data.find(a => a.id === appId);
      if (application !== undefined) application.logo = data;
    }
  },
  SET_META_PAYMENT(state, { app, data }) {
    state.metaPayment = { ...data, current_app: app };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
